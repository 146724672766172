import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { axiosInstant } from "../../common/api";
import { UploadDataFile } from "./UploadDataFile";
import { MappedFieldsInfo } from "./MappedFieldsInfo";
import { ShowProcessedData } from "./ShowProcessedData";
import {
  defaultMappedState,
  getFileColumnNames,
  RequiredFieldNames,
} from "./constants";
import { RequiredFieldsSelector } from "./RequiredFieldsSelector";

const { person, company } = RequiredFieldNames;
const selectedPersonFields = person?.slice(0, 9);
const selectedCompanyFields = company?.slice(0, 11);

export const PanelBody = ({ type, nextStep, prevStep }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [uploadedFile, setUploadedFile] = useState();
  const [fileData, setFileData] = useState([]);
  const [availableFields, setAvailableFields] = useState([]);

  const [mappedFields, setMappedFields] = useState(defaultMappedState(type));
  const [isMappingDone, setIsMappingDone] = useState(false);

  const [isFileViewDone, setIsFileViewDone] = useState(false);
  const [processableRows, setProcessableRows] = useState(0);

  const [requiredFields, setRequiredFields] = useState({
    showFileContents: true,
    person: selectedPersonFields,
    company: selectedCompanyFields,
  });

  const handleMappingUpdates = (key, value) => {
    setMappedFields((prev) => ({ ...prev, [key]: value }));
  };

  const onUploadFile = (file, data) => {
    setUploadedFile(file);
    setFileData(data);
    if (data.length) {
      const keys = getFileColumnNames(data);
      setAvailableFields(keys);
      setMappedFields(defaultMappedState(type, keys[0]));
    }
    nextStep();
  };

  const goToUploadScreen = () => {
    setUploadedFile(undefined);
    setFileData([]);
    setMappedFields(undefined);
    prevStep();
  };

  const uploadFileMetadata = async () => {
    const { name, type: contentType } = uploadedFile;
    const keyName = `${new Date().valueOf()}_${name}`;
    setLoading(true);
    try {
      const { data } = await axiosInstant.post("/s3/enrichmentFile", {
        keyName,
        contentType: contentType,
      });
      const url = data.url;
      if (url) {
        const headers = {
          "Content-Type": contentType,
          "Content-Disposition": "inline",
        };
        await axios.put(url, uploadedFile, { headers });
        await axiosInstant.post("/enrich/enqueueFile", {
          name,
          source: url?.split("?")[0],
          mapping: JSON.stringify(mappedFields),
          totalRows: processableRows,
          requiredFields: JSON.stringify(requiredFields),
          type,
        });
      }
      navigate("/enrichment");
    } catch (err) {
      console.log(err?.message);
    }
    setLoading(false);
  };

  const updateMappingView = (val) => {
    setIsMappingDone(val);
    val ? nextStep() : prevStep();
  };

  const onFileViewBack = () => {
    updateMappingView(false);
    prevStep();
  };

  const onFileViewSubmission = (totalRows) => {
    const isFileReviewDone = typeof totalRows === "number";
    setIsFileViewDone(isFileReviewDone);
    if (isFileReviewDone) {
      setProcessableRows(totalRows);
      nextStep();
    } else prevStep();
  };

  const updateRequiredFields = (field) => {
    setRequiredFields({ ...requiredFields, ...field });
  };

  const props = { type };

  if (!uploadedFile)
    return <UploadDataFile {...props} onUploadFile={onUploadFile} />;

  if (!(isMappingDone && mappedFields))
    return (
      <MappedFieldsInfo
        {...props}
        data={fileData}
        values={mappedFields}
        options={availableFields}
        onUpdate={handleMappingUpdates}
        onSubmit={() => updateMappingView(true)}
        goBack={goToUploadScreen}
      />
    );

  if (isMappingDone && mappedFields && !isFileViewDone)
    return (
      <ShowProcessedData
        {...props}
        data={fileData}
        mapping={mappedFields}
        goBack={onFileViewBack}
        onSubmit={onFileViewSubmission}
      />
    );

  return (
    <RequiredFieldsSelector
      loading={loading}
      totalRows={processableRows}
      onSubmit={uploadFileMetadata}
      goBack={onFileViewSubmission}
      defaultFields={availableFields}
      {...{ requiredFields, updateRequiredFields }}
    />
  );
};
