import React, { useState } from "react";
import axios from "axios"; // Make sure to install axios if not already
import { Dialog } from "@mui/material";
import { BaseURL, saveAuthData } from "../common/constants";
import { CreatePasswordForm } from "./CreatePasswordFormBody";

export function CreatePasswordDialog({ onClose, ...rest }) {
  const { email } = rest;
  const [formValues, setFormValues] = useState({
    password: "",
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const validate = () => {
    let tempErrors = {};
    tempErrors.password = formValues.password ? "" : "Password is not valid.";
    setErrors(tempErrors);
    return Object.values(tempErrors).every((x) => x === "");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!loading && validate()) {
      try {
        setLoading(true);
        const response = await axios.post(`${BaseURL}/auth/createPassword`, {
          email,
          ...formValues,
        });
        saveAuthData(response);
        onClose(); // Close popup if request is successful
        setTimeout(() => {
          window.location.reload();
        }, 200);
      } catch (error) {
        setErrors((prev) => ({
          ...prev,
          response: error?.response?.data?.message,
        }));
        setLoading(false);
        // Handle error (e.g., show error message to user)
      }
    }
  };

  const props = {
    handleInputChange,
    formValues,
    errors,
    handleSubmit,
    loading,
  };
  return (
    <React.Fragment>
      <Dialog
        open
        onClose={onClose}
        aria-labelledby="form-dialog-title"
        disableEscapeKeyDown
        fullWidth
        sx={{ backdropFilter: "blur(3px)" }} // Translucent backdrop
      >
        <CreatePasswordForm {...props} {...rest} />
      </Dialog>
    </React.Fragment>
  );
}
