import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import LandingPage from "./pages/landing";
import { logPageView } from "./helpers/analytics";
import { Layout } from "./components";
import { AuthProvider } from "./components/profile/AuthContext";

function RouteChangeTracker() {
  const location = useLocation();

  useEffect(() => {
    logPageView();
  }, [location]);

  return null;
}

function App() {
  return (
    <BrowserRouter>
      <RouteChangeTracker />
      <AuthProvider>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/*" element={<Layout />} />

        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
