import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Alert, Box, Button, Tab, Tabs, Typography } from "@mui/material";
import { a11yProps, tableStyles } from "./constants";
import { TabPanel } from "./TabPanel";
import { Link } from "react-router-dom";
import { goToSettingsTab, Settings } from "../../common/constants";
import { axiosInstant } from "../../common/api";

export const ProcessedDataView = ({
  totalRows,
  validData,
  invalidData,
  tableColumns,
  goBack,
  onSubmit,
}) => {
  const [value, setValue] = React.useState(0);
  const [creditsLoading, setCreditsLoading] = useState(true);
  const [credits, setCredits] = useState(0);

  useEffect(() => {
    axiosInstant
      .get("/user/credits")
      .then(({ data }) => {
        setCredits(data.credits);
      })
      .catch((err) => console.log(err?.message))
      .finally(() => {
        setCreditsLoading(false);
      });
  }, []);

  if (!validData && !invalidData) return "Loading...";

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const showFormattedData = (data) => {
    if (data.length)
      return (
        <DataGrid
          {...tableStyles(data?.length, { padding: 0 })}
          rows={data}
          columns={tableColumns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 20 },
            },
          }}
          pageSizeOptions={[20, 50, 100]}
        />
      );
    return <Typography>No data found</Typography>;
  };

  return (
    <>
      <Box>
        {totalRows > validData.length && (
          <Alert severity="warning" sx={{ height: 36, paddingY: 0 }}>
            We have found only <b>{validData.length}</b> valid entries from the
            file you have provided containing total <b>{totalRows}</b> rows.
          </Alert>
        )}
        {!creditsLoading && credits < validData.length && (
          <Alert severity="warning" sx={{ height: 36, paddingY: 0 }}>
            Your need <b>{validData.length - credits}</b> more credits to enrich
            this file, To purchase more,{" "}
            <Link to={goToSettingsTab(Settings.Billing)}>click here</Link>.
          </Alert>
        )}
      </Box>

      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="secondary"
        textColor="inherit"
        aria-label="full width tabs example"
      >
        <Tab label={`Valid (${validData.length})`} {...a11yProps(0)} />
        <Tab label={`Invalid (${invalidData.length})`} {...a11yProps(1)} />
      </Tabs>
      <TabPanel value={value} index={0} noSpacing>
        {showFormattedData(validData)}
      </TabPanel>
      <TabPanel value={value} index={1} noSpacing>
        {showFormattedData(invalidData)}
      </TabPanel>

      <Box display="flex" justifyContent="space-between" width="100%">
        <Button variant="outlined" onClick={goBack}>
          Re-map Fields
        </Button>
        {validData.length > 0 && (
          <Button
            variant="contained"
            onClick={() => onSubmit(validData.length)}
          >
            Continue
          </Button>
        )}
      </Box>
    </>
  );
};
