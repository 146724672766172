import React from "react";
import { Tabs, Tab } from "@mui/material";

export const EventsTab = ({ tabValue, setTabValue }) => {
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Tabs value={tabValue} onChange={handleTabChange} centered>
      <Tab label="Events" value="event" />
      <Tab label="Conferences" value="conference" />
    </Tabs>
  );
};
