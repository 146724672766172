import React, { useEffect, useState } from "react";
import { Typography, Box } from "@mui/material";
import { HistoryTableView } from "./components/HistoryTableView";
import { useSearchParams } from "react-router-dom";
import { PaymentSuccessPopup } from "./components/PaymentSuccessPopup";
import { UploadFileBtn } from "./UploadFileBtn";

export const Workspace = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);

  useEffect(() => {
    const showSuccess = searchParams.get("success");
    if (showSuccess === "true") {
      setShowSuccessPopup(true);
      setSearchParams({});
    }
  }, [searchParams]);

  return (
    <Box display="flex" paddingX={8} flexDirection="column" width="100%">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="start"
        marginTop={2} // Adjusts the space between the divider and the button
        paddingTop={4}
      >
        <Box>
          <Typography variant="h5">My Agents </Typography>
          <Typography variant="body1">
            The dashboard helps you monitor all your growth AI agents at work. You can pick an agent by clicking "Select An Agent" button
          </Typography>
        </Box>
        <UploadFileBtn />
      </Box>
      <HistoryTableView />
      {showSuccessPopup && (
        <PaymentSuccessPopup onClose={() => setShowSuccessPopup(false)} />
      )}
    </Box>
  );
};
