import React from "react";
import { Box, Chip } from "@mui/material";

export const EventsTags = ({
  years,
  cities,
  selectedYears,
  selectedCities,
  setSelectedYears,
  setSelectedCities,
}) => {
  const handleYearTagClick = (year) => {
    if (selectedYears.includes(year)) {
      setSelectedYears(
        selectedYears.filter((selectedYear) => selectedYear !== year)
      );
    } else {
      setSelectedYears([...selectedYears, year]);
    }
  };

  const handleCityTagClick = (city) => {
    if (selectedCities.includes(city)) {
      setSelectedCities(
        selectedCities.filter((selectedCity) => selectedCity !== city)
      );
    } else {
      setSelectedCities([...selectedCities, city]);
    }
  };

  return (
    <Box sx={{ mt: 2, display: "flex", flexWrap: "wrap", gap: 1 }}>
      {years.map((year) => (
        <Chip
          key={year}
          label={year}
          onClick={() => handleYearTagClick(year)}
          color={selectedYears.includes(year) ? "primary" : "info"}
          clickable
        />
      ))}
      {cities.map((city) => (
        <Chip
          key={city}
          label={city}
          onClick={() => handleCityTagClick(city)}
          color={selectedCities.includes(city) ? "primary" : "info"}
          clickable
        />
      ))}
    </Box>
  );
};
