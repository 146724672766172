import React, { createContext, useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import getLPtheme from "./getLPtheme";

// interface Props {
//   children: ReactNode;
// }

export const ThemeContext = createContext({
  mode: "",
  toggleMode: () => {},
});

export function Theme({ children }) {
  const [mode, setMode] = useState(localStorage.getItem("theme") || "light");
  //const [showCustomTheme, setShowCustomTheme] = React.useState(true);
  // const LPtheme = createTheme(getLPtheme(mode));
  // const defaultTheme = createTheme({ palette: { mode } });

  const toggleColorMode = () => {
    const theme = mode === "dark" ? "light" : "dark";
    setMode(theme);
    localStorage.setItem("theme", theme)
  };

  //{const toggleCustomTheme = () => {
  //setShowCustomTheme((prev) => !prev);}
  //};

  return (
    //<ThemeProvider theme={showCustomTheme ? LPtheme : defaultTheme}>
    <ThemeProvider theme={createTheme(getLPtheme(mode))}>
      <CssBaseline />
      <ThemeContext.Provider value={{ mode, toggleMode: toggleColorMode }}>
        {children}
      </ThemeContext.Provider>
      {/*<ToggleCustomTheme                 //Disabled the button
        //showCustomTheme={showCustomTheme}
        //toggleCustomTheme={toggleCustomTheme}
        //showCustomTheme={mode === 'dark'
        //toggleCustomTheme={toggleColorMode}
      />*/}
    </ThemeProvider>
  );
}
