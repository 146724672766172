import React from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import AutoAwesomeRoundedIcon from "@mui/icons-material/AutoAwesomeRounded";
import { UploadFileBtn } from "../UploadFileBtn";

export const EmptyTableView = ({ loading }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      width="100%"
      height={400}
      border="1px solid #ccc"
      borderTop={0}
    >
      {loading ? (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          gap={3}
        >
          <CircularProgress
            color="inherit"
            sx={{ marginLeft: 2, margin: 0 }}
            size={20}
          />
          <Typography variant="h5">Loading data</Typography>
        </Box>
      ) : (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          width={420}
          gap={3}
        >
          <AutoAwesomeRoundedIcon sx={{ fontSize: 60 }} />
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            gap={2}
          >
            <Typography variant="h5">Select an AI Growth Agent</Typography>
            <Typography variant="body1" textAlign="center">
              Enrich LinkedIn Data, Find Business Emails, Search Ideal Customer Profiles and do more with AI Agents.
            </Typography>
          </Box>
          <UploadFileBtn showText />
        </Box>
      )}
    </Box>
  );
};
