import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import { CreatePasswordDialog, LoginDialog, SignUpDialog } from "../auth";
import { getProfile } from "../common/constants";

const AuthContext = createContext({
  showLoginPopup: (isForced) => {},
  checkIsLoggedIn: () => false,
});

export const UseAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const timeoutId = useRef(-1);
  const location = useLocation();
  const [enableClose, setEnableClose] = useState(true);
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const [showPasswordPopup, setShowPasswordPopup] = useState(false);
  const [emailForPassword, setEmailForPassword] = useState("");
  const [showSignUpPopup, setShowSignUpPopup] = useState(false);

  const onSwitchAuth = () => {
    setShowSignUpPopup(!showSignUpPopup);
    setShowLoginPopup(!showLoginPopup);
  };

  const checkIsLoggedIn = () => {
    const { email, userId, token } = getProfile();
    return userId && token && email;
  };

  const handleShowLoginPopupReq = (isForced) => {
    setShowLoginPopup(true);
    if (isForced) setEnableClose(false);
  };

  useEffect(() => {
    clearTimeout(timeoutId.current);
    if (location.pathname.includes("event/")) {
      if (!checkIsLoggedIn()) {
        timeoutId.current = setTimeout(() => {
          if (!showLoginPopup && !showSignUpPopup) setShowSignUpPopup(true);
          setEnableClose(false);
        }, 15000);
      }
    }
  }, [location, showLoginPopup, showSignUpPopup]);

  return (
    <AuthContext.Provider
      value={{
        checkIsLoggedIn,
        showLoginPopup: handleShowLoginPopupReq,
      }}
    >
      {children}
      {showLoginPopup && (
        <LoginDialog
          onRegister={onSwitchAuth}
          onClose={() => (enableClose ? setShowLoginPopup(false) : {})}
          onCreatePassword={(email) => {
            setShowPasswordPopup(true);
            setShowLoginPopup(false);
            setEmailForPassword(email);
          }}
        />
      )}
      {emailForPassword && showPasswordPopup && (
        <CreatePasswordDialog
          email={emailForPassword}
          onClose={() => (enableClose ? setShowPasswordPopup(false) : {})}
        />
      )}
      {showSignUpPopup && (
        <SignUpDialog
          onLogin={onSwitchAuth}
          onClose={() => (enableClose ? setShowSignUpPopup(false) : {})}
        />
      )}
    </AuthContext.Provider>
  );
};
