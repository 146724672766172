import React from "react";
import { Alert } from "@mui/material";

export function AuthError({ ...commonProps }) {
  const { errors } = commonProps;

  if (errors.response)
    return (
      <Alert severity="error" sx={{ height: 36, paddingY: 0 }}>
        {errors.response}
      </Alert>
    );
  return <></>;
}
