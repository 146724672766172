import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  IconButton,
} from "@mui/material";
import EmailIcon from "@mui/icons-material/Email"; // Importing the email icon
import LinkedInIcon from "@mui/icons-material/LinkedIn"; // Importing the LinkedIn icon
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from "@mui/icons-material/Close";
import ContactMailIcon from '@mui/icons-material/ContactMail';
import { useNavigate } from "react-router-dom";
import { EnrichmentAgent } from "./EnrichmentAgent";

export const EnrichmentAgentSelector = ({ setOpen }) => {
  const navigate = useNavigate();
  const handleClose = () => setOpen(false);

  const handleCardClick = (route) => {
    handleClose(); // Close the dialog before navigating
    navigate(route);
  };

  return (
    <Dialog
      open
      onClose={handleClose}
      maxWidth="md"
      fullWidth
      PaperProps={{ sx: { height: "80vh", borderRadius: 3 } }}
    >
      <DialogTitle
        sx={{
          textAlign: "center", // Centers the text
          fontSize: "24px", // Increases the font size
          width: "100%", // Ensures it takes up the full width of the Dialog
          fontWeight: "bold", // Optional: makes the text bold
          paddingTop: "40px",
          paddingBottom: "40px",
        }}
      >
        Select an Agent
        <IconButton
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 16,
            top: 16,
            color: "grey.500",
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ paddingX: 3, paddingY: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <EnrichmentAgent
              title="LinkedIn Enrich"
              description={`This agent takes a CSV file containing LInkedIn URLs. Goes to each URL and fetches info as per the instructions. Returns enriched CSV`}
              icon={<LinkedInIcon sx={{ fontSize: 24, color: "#0077b5" }} />} // LinkedIn icon with color
              onClick={() => handleCardClick("/Enrichment/Linkedin")}
              isAvailable={true}
              creditsPerCycle={5}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <EnrichmentAgent
              title="Email Finder"
              description={`<b>Coming Soon</b>. The AI agent helps you find business emails of people in bulk. Takes in CSV with name + domain and outputs enriched CSV`}
              icon={<EmailIcon sx={{ fontSize: 24, color: "#f44336" }} />} // Email icon with color
              // onClick={() => handleCardClick("/Enrichment/email")}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <EnrichmentAgent
              title="AI Prospecting"
              description={`<b>Coming Soon</b>. The AI agent helps you find business find customers who exactly match your customer description.`}
              icon={<SearchIcon sx={{ fontSize: 24, color: "#f44336" }} />} // Email icon with color
              // onClick={() => handleCardClick("/Enrichment/email")}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <EnrichmentAgent
              title="Personalised Emails"
              description={`<b>Coming Soon</b>. Write personalized emails to your customers in bulk. The agents take their name + Linkedin/Website to write personal emails`}
              icon={<ContactMailIcon sx={{ fontSize: 24, color: "#f44336" }} />} // Email icon with color
              // onClick={() => handleCardClick("/Enrichment/email")}
            />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
