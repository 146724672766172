import React, { useMemo } from "react";
import { SelectField } from "./SelectField";
import { EnrichTypes, isLinkedinUrl, TableColumns } from "./constants";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { isUrl } from "../../common/constants";

export const MappedFieldsView = ({ type, ...rest }) => {
  const tableColumns = useMemo(() => {
    if (type === EnrichTypes.Email) {
      return (
        <>
          <SelectField
            label="First Name"
            name={TableColumns.FirstName}
            {...rest}
          />
          <SelectField
            label="Last Name"
            name={TableColumns.LastName}
            {...rest}
          />
          <SelectField
            label="Company Website"
            name={TableColumns.Company}
            validateUrl={isUrl}
            {...rest}
          />
        </>
      );
    }

    if (type === EnrichTypes.Linkedin) {
      return (
        <SelectField
          label="LinkedIn URL"
          name={TableColumns.Linkedin}
          validateUrl={isLinkedinUrl}
          {...rest}
        />
      );
    }

    return <></>;
  }, [type, rest.values]);

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ marginLeft: 2 }}>Column</TableCell>
            <TableCell sx={{ marginLeft: 2 }}>File Column</TableCell>
            <TableCell>Preview</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{tableColumns}</TableBody>
      </Table>
    </TableContainer>
  );
};
