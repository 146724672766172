import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

export function EventDetail({ event }) {
  return (
    <Box sx={{ p: 2 }}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography gutterBottom variant="h5" component="div">
          {event?.name}
        </Typography>
      </Stack>
      <Typography color="text.secondary" variant="body2">
        {event?.description}
      </Typography>
    </Box>
  );
}
