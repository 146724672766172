import { createTheme } from '@mui/material/styles';
import { grey } from '@mui/material/colors';

// Light theme with minimal overrides
const lightTheme = createTheme({
  palette: {
    mode: 'light',
    background: {
      default: "#ffffff",  // White background for light mode
      paper: "#f5f5f5",    // Light grey for paper elements
    },
    text: {
      primary: "#000000",  // Black text color for light mode
    },
  },
});

// Dark theme with minimal overrides
const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: '#090E10',  // Dark grey background for dark mode
      // paper: grey[800],    // Slightly lighter grey for paper elements
    },
    text: {
      primary: "#ffffff", 
       // White text color for dark mode
    },
  },
});

export { lightTheme, darkTheme };


