import { Box, Button, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { getProfile, saveAuthData } from "../common/constants";
import { FieldText, AuthError } from "../auth";
import { initProfileErrors } from "./constants";
import { axiosInstant } from "../common/api";

export function AccountSettings() {
  const { email, userId, ...profileData } = getProfile();

  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState(profileData);
  const [errors, setErrors] = useState(initProfileErrors);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const validate = () => {
    const tempErrors = {};
    tempErrors.name = formValues.name ? "" : "This field is required.";
    tempErrors.company = formValues.company ? "" : "This field is required.";
    setErrors(tempErrors);
    return Object.values(tempErrors).every((x) => x === "");
  };

  const updateSettings = async (event) => {
    event.preventDefault();
    if (!loading && validate()) {
      try {
        setLoading(true);
        await axiosInstant.patch("/auth/profile", formValues);
        saveAuthData({ data: formValues });
        setLoading(false);
      } catch (error) {
        setErrors((prev) => ({
          ...prev,
          response: error?.response?.data?.message || "",
        }));
        setLoading(false);
      }
    }
  };

  const commonProps = { formValues, errors, handleInputChange };
  return (
    <div>
      <Typography variant="h5">Account Settings</Typography>
      <Box marginY={5} display="flex" flexDirection="column" gap={2}>
        <TextField label="Email" variant="outlined" value={email} disabled />
        <Box display="flex" gap={2}>
          <FieldText {...commonProps} name="name" label="Name" />
          <FieldText {...commonProps} name="company" label="Company" />
        </Box>
        <AuthError {...commonProps} />
      </Box>
      <Box display="flex" justifyContent="flex-end">
        <Button
          onClick={updateSettings}
          disabled={loading}
          variant={loading ? "outlined" : "contained"}
        >
          {loading ? "Updating..." : "Update"}
        </Button>
      </Box>
    </div>
  );
}
