import React from "react";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import {
  PricingCardTitle,
  PricingCheckbox,
  PricingSubTitle,
  StyledChip,
} from "./styles";
export function PricingSingleCard({ tier }) {
  return (
    <CardContent>
      <PricingCardTitle $tier={tier} sx={{ marginBottom: "0px" }}>
        <Typography component="h3" variant="h6">
          {tier.title}
        </Typography>
        {tier.title === "Professional" && (
          <StyledChip
            icon={<AutoAwesomeIcon />}
            label={tier.subheader}
            size="small"
          />
        )}
      </PricingCardTitle>
      <PricingSubTitle $tier={tier}>
        <Typography component="h4" variant="h4">
          {tier.price}
        </Typography>
        <Typography component="h4" variant="h4">
          {tier.priceunit}
        </Typography>
      </PricingSubTitle>
      <Divider
        sx={{
          my: 2,
          opacity: 0.2,
          borderColor: "grey.500",
        }}
      />
      {tier.description.map((line) => (
        <Box
          key={line}
          sx={{
            py: 1,
            display: "flex",
            gap: 1.5,
            alignItems: "center",
          }}
        >
          <PricingCheckbox $tier={tier} />
          <Typography
            component="text"
            variant="subtitle2"
            sx={{
              color: tier.title === "Professional" ? "grey.200" : undefined,
            }}
          >
            {line}
          </Typography>
        </Box>
      ))}
    </CardContent>
  );
}
