import React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { pricingTiers } from "./constants";
import { PricingContainerStyle, PricingWrapperStyle } from "./styles";
import { PricingSingleCard } from "./PricingCard";
import { StyledPricingCard } from "./styles";
export function Pricing() {
  return (
    <Container id="pricing" sx={PricingContainerStyle}>
      <Box sx={PricingWrapperStyle}>
        <Typography
          component="h2"
          variant="h3"
          align="center"
          color="text.primary"
          sx={{
            fontFamily: "Inter, Sans-serif",
            paddingBottom: "15px",
            fontWeight: "bold",
            wordBreak: "break-word",
          }}
        >
          Pricing
        </Typography>
        <Typography
          variant="body1"
          color="text.secondary"
          fontFamily="Inter, Sans-serif"
          sx={{
            fontSize: "18px",
            lineHeight: "1.5",
            color: "grey",
            textAlign: "center",
            paddingBottom: "50px",
            paddingTop: "0px",
            maxWidth: "580px",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          We provide free credits to try out our agents. Afterwards, you can buy
          credits as per your need.
        </Typography>
      </Box>
      <Grid
        container
        spacing={3}
        alignItems="center"
        justifyContent="center"
        fontFamily="Inter, Sans-serif"
      >
        {pricingTiers.map((tier) => (
          <Grid
            item
            key={tier.title}
            xs={12}
            md={tier.title === "Enterprise" ? 12 : 6}
            lg={4}
          >
            <StyledPricingCard $tier={tier}>
              <PricingSingleCard tier={tier} />
            </StyledPricingCard>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}