import { Box, Card, Container, styled, Typography } from "@mui/material";

export const EventsMainContainer = styled(Container)(() => ({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
}));

export const eventContainerStyles = {
  pt: { xs: 4, sm: 12 },
  pb: { xs: 8, sm: 16 },
  gap: { xs: 3, sm: 6 },
};

export const EventPageTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.mode === "dark" ? "#fff" : "#000",
  marginBottom: "10px",
}));

export const EventCard = styled(Card)(() => ({
  width: 250,
  height: 300,
  margin: "20px",
  padding: "10px",
  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
}));

export const EventDetailBox = styled(Box)(() => ({
  height: "50%",
  padding: "5px",
  alignItems: "center",
  textAlign: "center",
  lineHeight: "0.5",
  fontFamily: "Arial, sans-serif",
}));

export const EventName = styled(Typography)(({ theme }) => ({
  marginBottom: "3px",
  color: theme.palette.mode === "dark" ? "#fff" : "#000",
  fontSize: "16px",
  fontWeight: "bold",
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
}));

export const EventLocation = styled(Typography)(({ theme }) => ({
  marginBottom: "4px",
  color: theme.palette.mode === "dark" ? "#ccc" : "#444",
  fontSize: "14px",
}));

export const EventTime = styled(Typography)(({ theme }) => ({
  color: theme.palette.mode === "dark" ? "#999" : "#666",
  fontSize: "12px",
}));
