import * as React from "react";
import * as Enrich from "./constants";
import { Typography } from "@mui/material";
import { ChevronRight } from "@mui/icons-material";

export const SingleEnrichmentOption = ({ head, body, ...rest }) => {
  return (
    <Enrich.OptionContainer {...rest}>
      <Enrich.OptionTextWraper>
        <Typography variant="h5">{head}</Typography>
        <Typography variant="body1">{body}</Typography>
      </Enrich.OptionTextWraper>
      <ChevronRight fontSize="large" />
    </Enrich.OptionContainer>
  );
};
