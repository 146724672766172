import React from "react";
import { Box, Button } from "@mui/material";
import { LgMenuItem } from "../landing/header/LgMenuItem";
import { SmMenuItem } from "../landing/header/SmMenuItem";
import { ToggleColorMode } from "../landing/header/ToggleColorMode";
import { UseAuth } from "./AuthContext";

export function AuthPanel(props) {
  const { showLoginPopup } = UseAuth();
  const { isLandingPage = false, isMobileView = false } = props;

  const commonProps = {
    label: "Sign in",
    onClick: showLoginPopup,
    color: "secondary",
  };
  return (
    <Box display="flex" gap={1} alignItems="center">
      {!isMobileView && <ToggleColorMode {...props} />}
      {isLandingPage ? (
        <>
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            <SmMenuItem {...commonProps} />
          </Box>
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <LgMenuItem {...commonProps} />
          </Box>
        </>
      ) : (
        <Button {...commonProps} sx={{ color: "white" }}>
          Sign In
        </Button>
      )}
    </Box>
  );
}
