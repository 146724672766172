import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import MenuIcon from "@mui/icons-material/Menu";
import { ToggleColorMode } from "./ToggleColorMode";
import { LgMenuItem } from "./LgMenuItem";
import { SmMenuItem } from "./SmMenuItem";
import { Profile } from "../../profile";

export function SmMenuList({ open, setOpen, ...props }) {
  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  return (
    <Box sx={{ display: { sm: "", md: "none" } }}>
      <Button
        variant="text"
        color="primary"
        aria-label="menu"
        onClick={toggleDrawer(true)}
        sx={{ minWidth: "30px", p: "4px" }}
      >
        <MenuIcon />
      </Button>
      <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
        <Box
          sx={{
            minWidth: "60dvw",
            p: 2,
            backgroundColor: "background.paper",
            flexGrow: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "end",
              flexGrow: 1,
            }}
          >
            <ToggleColorMode isLandingPage />
          </Box>
          <SmMenuItem label="Explore Events" href="/events" />
          <SmMenuItem label="Testimonials" {...props} />
          <SmMenuItem label="Pricing" {...props} />
          <SmMenuItem label="FAQ" {...props} />
          <Divider />
          <Profile isLandingPage isMobileView />
        </Box>
      </Drawer>
    </Box>
  );
}
