import React from "react";
import Box from "@mui/material/Box";
import { LgMenuItem } from "./LgMenuItem";
import { Profile } from "../../profile";

export function LgMenuList({ ...props }) {
  return (
    <>
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          px: 0,

        }}
      >
        <Box
          sx={{
            display: { xs: "none", md: "flex" },
            alignItems: "center", // Center items vertically

          }}>
       
          <LgMenuItem label="Agents" {...props} />
          <LgMenuItem label="Testimonials" {...props} />
          <LgMenuItem label="Pricing" {...props} />
          <LgMenuItem label="FAQ" {...props} />
          
        </Box>
        </Box>
      
    
    </>
  );
}
