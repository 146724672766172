import { deepOrange } from "@mui/material/colors";
import Button from "@mui/material/Button";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { openStripeUrlPage } from "../common/constants";
import { UseAuth } from "../profile/AuthContext";

export function AlertToFreeUser({ event, tableRowsCount }) {
  const { checkIsLoggedIn, showLoginPopup } = UseAuth();

  const handleClick = () => {
    if (checkIsLoggedIn()) openStripeUrlPage(event);
    else showLoginPopup();
  };

  if (tableRowsCount > 20) return null;
  return (
    <Button
      sx={{
        bgcolor: deepOrange[500],
        width: { xs: "100%", sm: "auto" },
        position: { xs: "fixed", sm: "relative" },
        bottom: { xs: 0, sm: "auto" },
        left: { xs: 0, sm: "auto" },
        zIndex: 1,
      }}
      variant="contained"
      onClick={handleClick}
      startIcon={<LockOpenIcon />}
    >
      Unlock All Data
    </Button>
  );
}
