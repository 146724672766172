import React from "react";
import { Button, Typography } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

export const GoBackBtn = () => {
  const navigate = useNavigate();
  return (
    <Button
      variant="text"
      onClick={() => navigate(-1)}
      sx={{ gap: 1, marginBottom: 1 }}
    >
      <ArrowBack />
      <Typography>Back</Typography>
    </Button>
  );
};
