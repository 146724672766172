import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";

export function EventBreadCrumbs({ event }) {
  return (
    <>
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          component={RouterLink}
          to="/events"
          underline="hover"
          color="inherit"
        >
          Events
        </Link>
        <Typography color="text.primary">{event?.name}</Typography>
      </Breadcrumbs>{" "}
      <br />
    </>
  );
}
