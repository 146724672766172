import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { AlertToFreeUser } from "./AlertToFreeUser";

export function EventLocation({ tableRows, event }) {
  return (
    <Box sx={{ p: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3}>
          {event?.city}, {event?.country}
        </Grid>
        <Grid item xs={12} sm={5}></Grid>
        <Grid
          item
          xs={12}
          sm={4}
          sx={{ textAlign: { xs: "center", sm: "right" } }}
        >
          <AlertToFreeUser tableRowsCount={tableRows.length} event={event} />
        </Grid>
      </Grid>
    </Box>
  );
}
