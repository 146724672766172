import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  Typography,
  Chip,
  CircularProgress,
  Autocomplete,
  TextField,
} from "@mui/material";
import {
  AntSwitch,
  getFilteredOptions,
  removeExistingField,
  RequiredFieldNames,
  StyledChipArray,
} from "./constants";

export const RequiredFieldsSelector = ({
  onSubmit,
  defaultFields,
  requiredFields: { showFileContents, person, company },
  updateRequiredFields,
  goBack,
  loading,
  totalRows,
}) => {
  const [value, setValue] = useState(null);
  const [personFields, setPersonFields] = useState(person);
  const [companyFields, setCompanyFields] = useState(company);
  const [selectedFields, setSelectedFields] = useState([...person, ...company]);

  const deleteChip = (field) => {
    setSelectedFields(removeExistingField(selectedFields, field));
    setPersonFields(removeExistingField(personFields, field));
    setCompanyFields(removeExistingField(companyFields, field));
  };

  const updateChip = (val) => {
    setValue(val);
    setTimeout(() => setValue(null), 100);
    setSelectedFields((prev) => [...prev, val.name]);
    if (val.type === "person") setPersonFields((prev) => [...prev, val.name]);
    if (val.type === "company") setCompanyFields((prev) => [...prev, val.name]);
  };

  useEffect(() => {
    updateRequiredFields({ person: personFields, company: companyFields });
  }, [personFields, companyFields]);

  const dropdownOptions = useMemo(() => {
    const personOptions = getFilteredOptions(
      RequiredFieldNames.person,
      selectedFields
    ).map((name) => ({ name, type: "person" }));

    const companyOptions = getFilteredOptions(
      RequiredFieldNames.company,
      selectedFields
    ).map((name) => ({ name, type: "company" }));

    return [...personOptions, ...companyOptions];
  }, [selectedFields]);

  return (
    <Box display="flex" flexDirection="column" gap={4} width="100%">
      <Typography variant="h5">Select your required columns</Typography>
      <Box display="flex" flexDirection="column" gap={2} width="100%">
        <Box display="flex" alignItems="center" gap={1} paddingX={1}>
          <AntSwitch
            checked={showFileContents}
            onChange={(_, checked) =>
              updateRequiredFields({ showFileContents: checked })
            }
          />
          <Typography variant="body1">Include file contents</Typography>
        </Box>
        <StyledChipArray>
          {showFileContents &&
            defaultFields?.map((field, index) => (
              <Chip key={`defaultChip-${index}`} label={field} />
            ))}
          {selectedFields.map((field, index) => (
            <Chip
              key={`selectedChip-${index}`}
              label={field}
              onDelete={() => deleteChip(field)}
            />
          ))}
          {dropdownOptions.length && (
            <Autocomplete
              options={dropdownOptions}
              getOptionLabel={(option) => option.name}
              id="clear-on-escape"
              disableCloseOnSelect
              disableClearable
              value={value}
              sx={{ width: 200 }}
              onChange={(e, f) => updateChip(f)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  // value={selectedField}
                  variant="standard"
                  placeholder="Select Fields"
                  inputProps={{ ...params.inputProps, sx: { height: 32 } }}
                />
              )}
            />
          )}
        </StyledChipArray>
      </Box>

      <Box display="flex" justifyContent="space-between">
        <Button variant="outlined" onClick={goBack} disabled={loading}>
          Review file
        </Button>
        <Button
          variant={loading ? "outlined" : "contained"}
          onClick={() => onSubmit(totalRows)}
          disabled={loading}
        >
          {loading ? "Starting..." : `Start Enriching ${totalRows} entries`}
          {loading && (
            <CircularProgress
              color="inherit"
              sx={{ marginLeft: 2 }}
              size={20}
            />
          )}
        </Button>
      </Box>
    </Box>
  );
};
