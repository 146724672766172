import React, { useEffect } from "react";
import { UseAuth } from "../../profile/AuthContext";
import { DataGrid } from "@mui/x-data-grid";
import { axiosHandler } from "../../common/api";
import {
  EnrichHistoryPollingSecs,
  EnrichmentTableColumns,
  getFormattedHistory,
  HistoryTable,
  tableStyles,
} from ".";
import { EmptyTableView } from "./EmptyTableView";

export const HistoryTableView = () => {
  const { showLoginPopup, checkIsLoggedIn } = UseAuth();
  const [history, setHistory] = React.useState();
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    const hisotryRequest = axiosHandler("/enrich/history");
    if (!checkIsLoggedIn()) {
      showLoginPopup(true);
      setLoading(false);
    } else {
      hisotryRequest
        .poll(EnrichHistoryPollingSecs)
        .get(({ data }) => {
          setHistory(getFormattedHistory(data));
          setLoading(false);
        })
        .catch((err) => {
          if (!err?.message) return;
          setLoading(false);
        });
    }
    return () => {
      hisotryRequest.cancel("get");
    };
  }, []);

  return (
    <HistoryTable>
      <DataGrid
        {...tableStyles(history?.length)}
        rows={history || []}
        columns={EnrichmentTableColumns}
      />
      {!history?.length && <EmptyTableView loading={loading} />}
    </HistoryTable>
  );
};
