import { Box, Typography } from "@mui/material";
import React from "react";
import { Loader } from "./Loader";
import { PurchaseList } from "./PurchaseList";

export function PurchaseHistory({ purchases }) {

  return (
    <div>
      <Typography variant="h5">Purchase History</Typography>
      <Box marginY={5} display="flex" flexDirection="column" gap={2}>
        {!purchases ? <Loader /> : <PurchaseList purchases={purchases} />}
      </Box>
    </div>
  );
}
