import {
  Box,
  Button,
  styled,
  TableCell,
  tableCellClasses,
  TableRow,
} from "@mui/material";
import { brand } from "../../theme/getLPtheme";
import { Done } from "@mui/icons-material";

export const getTabProps = (index) => ({
  id: `vertical-tab-${index}`,
  value: index,
  "aria-controls": `vertical-tabpanel-${index}`,
  sx: {
    textTransform: "none",
    alignItems: "start",
    textAlign: "left",
  },
});

export const SettingsContainer = styled(Box)(() => ({
  display: "flex",
  width: "100%",
  height: "100%",
}));

export const SettingsTabStyle = {
  sx: {
    display: "flex",
    borderRight: 1,
    borderColor: "divider",
    minHeight: { xs: "100%", sm: "calc(100vh - 64px)" },
    minWidth: { xs: "15%" },
    marginTop: { xs: 0, sm: 2 },
  },
};

export const SettingsDetails = styled(Box)(() => ({
  padding: 36,
}));

export const initProfileErrors = {
  name: "",
  company: "",
};

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: "#fff",

  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export const pricing = [
  {
    name: "Starter",
    price: 79,
    perks: ["10,000 API Credits", "Access to all AI Agents"],
    monthlyPaymentId: process.env.REACT_APP_MONTHLY_STARTER_KEY,
    yearlyPaymentId: process.env.REACT_APP_ANNUAL_STARTER_KEY,
  },
  {
    name: "Growth",
    price: 249,
    perks: ["40,000 API Credits", "Access to all AI Agents"],
    yearlyPaymentId: process.env.REACT_APP_ANNUAL_GROWTH_KEY,
  },
  {
    name: "Professional",
    price: 549,
    perks: ["100,000 API Credits", "Access to all AI Agents"],
  },
  {
    name: "Enterprise",
    text: "Custom",
    perks: [
      "Need more than 3,000,000 API Credits with all premium features and dedicated support?",
    ],
  },
];

export const PricingCardStyle = styled(Box)(({ theme }) => ({
  padding: "16px 24px",
  display: "flex",
  gap: 8,
  flexDirection: "column",
  width: 290,
  minHeight: 350,
  position: "relative",
  border: `1px solid ${theme.palette.mode === "light" ? "#0000003b" : "#fff"}`,
}));

export const PricingCardBtn = styled(Button)(({ theme, $active }) => ({
  width: "100%",
  border: `1px solid ${theme.palette.mode === "light" ? "#0000003b" : "#fff"}`,
  borderRadius: 0,
  color: $active || theme.palette.mode === "dark" ? "#fff" : "#000000",
  backgroundColor: $active ? theme.palette.primary.main : "transparent",
  fontSize: 16,
  "&:hover": {
    border: `1px solid ${
      theme.palette.mode === "light" ? "#0000003b" : "#fff"
    }`,
    backgroundColor: $active
      ? theme.palette.mode === "dark"
        ? theme.palette.primary.dark
        : brand[400]
      : theme.palette.mode === "dark"
      ? "#666"
      : "#ccc",
    outline: "none",
    boxShadow: "none",
  },
}));

export const pricingBtnProps = (onClickFunc) => ({
  variant: "outlined",
  onClick: onClickFunc,
});

export const CheckIcon = styled(Done)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: 18,
}));
