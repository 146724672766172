import { Alert } from "@mui/material";
import { openStripeUrlPage } from "../common/constants";
import { UseAuth } from "../profile/AuthContext";

export function BannerForFreeUser({ event, tableRowsCount }) {
  const { checkIsLoggedIn, showLoginPopup } = UseAuth();

  if (tableRowsCount > 20) return null;

  const handleClick = () => {
    if (checkIsLoggedIn()) openStripeUrlPage(event);
    else showLoginPopup();
  };

  return (
    <Alert severity="warning">
      Right now you can only view 20 people who attended the conference.
      <a href="#" onClick={handleClick}>
        {" "}
        Click Here{" "}
      </a>{" "}
      to download data of all the people who attended
    </Alert>
  );
}
