import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { CurrentPlan } from "./CurrentPlan";
import { PlanCards } from "./PlanCards";
import { axiosInstant } from "../common/api";
import { Loader } from "./Loader";
import { PricingCardBtn } from "./constants";

export function BillingPlans() {
  const [isYearly, setIsYearly] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();

  useEffect(() => {
    setLoading(true);
    axiosInstant("/user/credits")
      .then(({ data: res }) => {
        setData(res);
        setIsYearly(res?.plan_name?.toLowerCase().includes("annual"));
      })
      .catch((err) => console.log(err?.message))
      .finally(() => setLoading(false));
  }, []);

  const getSwitchBtn = (label, shouldshowCustomStyle) => (
    <PricingCardBtn
      $active={shouldshowCustomStyle}
      sx={{ width: 150 }}
      onClick={() => setIsYearly(label === "Annually")}
    >
      {label} Billing
    </PricingCardBtn>
  );

  if (loading) return <Loader />;

  const props = { data, isYearly };
  return (
    <div>
      <Typography variant="h5">Billing & Plans</Typography>
      <CurrentPlan {...props} />
      <Box
        marginY={4}
        display="flex"
        flexDirection="column"
        gap={4}
        sx={{ cursor: "pointer" }}
      >
        <Box display="flex" justifyContent="flex-end">
          {getSwitchBtn("Monthly", !isYearly)}
          {getSwitchBtn("Annually", isYearly)}
        </Box>
      </Box>
      <PlanCards {...props} />
    </div>
  );
}
