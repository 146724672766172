import { AppBar, Toolbar, styled, Box } from "@mui/material";

export const HeaderLinksStyle = { textDecoration: "none" };

export const StyledAppBar = styled(AppBar)(({ theme }) => ({
  boxShadow: "none",
  background: theme.palette.mode === "dark" ? "#090E10" : "#fff",
  backgroundImage: "none",
  marginTop: 0,
  position: "sticky",
  left: 0,
  width: "100%",
  overflow: "hidden",
  paddingLeft: "24px",
  paddingRight: "24px",
  // Add media queries using Material UI's theme breakpoints
  [theme.breakpoints.down("lg")]: {
    paddingLeft: "4px",
    paddingRight: "4px",
  },
}));

export const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  paddingTop: "15px",
  minHeight: "64px",
  background: theme.palette.mode === "dark" ? "#090E10" : "#fff",
  boxShadow: "none",
  fontFamily: "Inter, Sans-serif",
  fontSize: "30px",
  width: "100%",
}));

export const LogoContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  textAlign: "left",
  minWidth: "126px",
  // Add media queries using Material UI's theme breakpoints
  [theme.breakpoints.down("lg")]: {
    minWidth: "10px",
  },
}));

export const LinksContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  textAlign: "right",
  paddingLeft: "20px",
  // Add media queries using Material UI's theme breakpoints
  [theme.breakpoints.down("sm")]: {
    marginLeft: "auto",
  },
}));

export const RightContainer = styled("div")(({ theme }) => ({
  marginLeft: "auto",
  display: "flex",
  alignItems: "center",
  // Add media queries using Material UI's theme breakpoints
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
}));
