import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { tableColumnProps, tableStyles } from "./constants";
import { Box, CircularProgress, Typography, useTheme } from "@mui/material";
import { isEmpty, startCase } from "lodash";

export const OutputFileView = ({ url }) => {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [tableColumns, setTableColumns] = useState([]);

  const processFile = async () => {
    setLoading(true);
    const urlParts = url.split(".");
    urlParts.pop();
    const jsonUrl = `${urlParts.join(".")}.json`;
    try {
      const res = await fetch(jsonUrl);
      const rows = await res.json();

      let currentKeysCount = 0;
      let headers = [];
      rows.forEach((row) => {
        const rowKeys = Object.keys(row);
        if (currentKeysCount < rowKeys.length) {
          currentKeysCount = rowKeys.length;
          headers = rowKeys;
        }
      });

      const results = rows
        .filter((e) => !!e && !isEmpty(e))
        .map((e, index) => ({ ...e, id: e.id || `valid-${index}` }));
      setData(results);

      setTableColumns(
        headers.map((field) => ({
          ...tableColumnProps,
          field,
          headerName: startCase(
            field
              .replace(/([A-Z][a-z]+)/g, " $1 ")
              .replaceAll('"', "")
              .trim()
          ),
          minWidth: 200,
        }))
      );
    } catch (e) {
      console.log(e?.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (url) processFile();
  }, [url]);

  if (loading) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  if (data.length && tableColumns.length) {
    return (
      <DataGrid
        {...tableStyles(data?.length, {
          backgroundColor: theme.palette.mode === "light" ? "#fff" : "#000",
        })}
        rows={data || []}
        columns={tableColumns}
      />
    );
  }

  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <Typography variant="body1">No Data Available</Typography>
    </Box>
  );
};
