import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  DialogActions,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { getFormattedDate } from "../../common/constants";
import { axiosInstant } from "../../common/api";

export const PaymentSuccessPopup = ({ onClose }) => {
  const [data, setData] = useState();
  const [show, setShow] = useState(false);

  useEffect(() => {
    axiosInstant("/user/credits")
      .then(({ data: res }) => {
        setData(res);
        setShow(true);
      })
      .catch((err) => console.log(err?.message));
  }, []);

  return (
    <Dialog
      open={show}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{ sx: { borderRadius: 3 } }}
    >
      <DialogTitle
        sx={{
          textAlign: "center",
          fontSize: "24px",
          width: "100%",
          fontWeight: "bold",
        }}
      >
        Congratulations!
        <IconButton
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 16,
            top: 16,
            color: "grey.500",
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ paddingX: 3, paddingY: 2 }}>
        <ul>
          {data?.plan_name && (
            <li>
              <Typography>
                You have successfully subscribed to our {data?.plan_name}{" "}
                Package.
              </Typography>
            </li>
          )}
          {!!data?.credits && (
            <li>
              <Typography>
                Your commulative credits are {data?.credits} now.
              </Typography>
            </li>
          )}
          {data?.expires_at && (
            <li>
              <Typography>
                The expiry date of your credits is{" "}
                {getFormattedDate(data.expires_at)}.
              </Typography>
            </li>
          )}
        </ul>
      </DialogContent>
      <DialogActions sx={{ paddingX: 3, paddingY: 2 }}>
        <Button onClick={onClose} variant="contained">
          Okay
        </Button>
      </DialogActions>
    </Dialog>
  );
};
