import React from "react";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import { HeaderLinksStyle } from "./styles";

export function LgMenuItem({ label, href, onClick }) {
  const menuItem = (
    <MenuItem
      onClick={() => onClick?.(label.toLowerCase())}
      sx={{ py: "6px", px: "12px"}}
    >
      <Typography variant="h5" color="#fffff" fontFamily={"Inter, Sans-serif"}>
        {label}
      </Typography>
    </MenuItem>
  );

  if (!href) return menuItem;
  return (
    <Link to={href} style={HeaderLinksStyle}>
      {menuItem}
    </Link>
  );
}
