import React, { useContext } from "react";
import Box from "@mui/material/Box";
import WbSunnyRoundedIcon from "@mui/icons-material/WbSunnyRounded";
import ModeNightRoundedIcon from "@mui/icons-material/ModeNightRounded";
import { ThemeContext } from "../../../theme";
import { IconButton } from "@mui/material";

export function ToggleColorMode({ isLandingPage = false }) {
  const { mode, toggleMode } = useContext(ThemeContext);

  return (
    <Box sx={{ maxWidth: "32px" }}>
      <IconButton
        variant="text"
        color={isLandingPage ? "primary" : "inherit"}
        onClick={toggleMode}
        size="small"
        aria-label="button to toggle theme"
        sx={{ minWidth: "32px", height: "32px", p: "4px" }}
      >
        {mode === "dark" ? (
          <WbSunnyRoundedIcon fontSize="small" />
        ) : (
          <ModeNightRoundedIcon fontSize="small" />
        )}
      </IconButton>
    </Box>
  );
}
