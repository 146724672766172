import React, { useEffect, useMemo, useState } from "react";
import axios from "axios";
import Grid from "@mui/material/Grid";
import { EventThumbnail } from "./EventsThumbnail";
import { eventContainerStyles, EventsMainContainer } from "./styles";
import { PageHeading } from "./PageHeading";
import { EventsTags } from "./EventTags";
import { EmptyEventPanel } from "./EmptyEventsPanel";
import { EventsTab } from "./EventTab";
import { BaseURL } from "../common/constants";

export const EventsPage = () => {
  const [loading, setLoading] = useState(false);
  const [events, setEvents] = useState([]);
  const [years, setYears] = useState([]);
  const [cities, setCities] = useState([]);
  const [tabValue, setTabValue] = useState("event");
  const [selectedYears, setSelectedYears] = useState([]);
  const [selectedCities, setSelectedCities] = useState([]);

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${BaseURL}/event/getAllEvents`)
      .then((response) => {
        console.log("Fetched events:", response.data);
        const uniqueYears = [
          ...new Set(
            response.data.map((event) =>
              new Date(event.start_date).getFullYear()
            )
          ),
        ];
        setYears(uniqueYears.filter((year) => year)); // Filter out null values
        setEvents(response.data);
        const uniqueCities = [
          ...new Set(response.data.map((event) => event.city)),
        ];
        setCities(uniqueCities.filter((city) => city)); // Filter out null values
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching events:", error);
        setLoading(false);
      });
  }, []);

  const filteredEvents = useMemo(
    () =>
      events.filter((event) => {
        const eventYear = new Date(event.start_date).getFullYear();
        const eventCity = event.city;
        const matchesTab = event.type === tabValue;
        const matchesYear =
          selectedYears.length === 0 || selectedYears.includes(eventYear);
        const matchesCity =
          selectedCities.length === 0 || selectedCities.includes(eventCity);

        return matchesTab && matchesYear && matchesCity;
      }),
    [events, selectedCities, selectedYears, tabValue]
  );

  const tagsProps = {
    years,
    cities,
    selectedYears,
    selectedCities,
    setSelectedCities,
    setSelectedYears,
  };

  return (
    <EventsMainContainer sx={eventContainerStyles}>
      <PageHeading />
      <EventsTab {...{ tabValue, setTabValue }} />
      <EventsTags {...tagsProps} />
      <Grid container spacing={2} justifyContent="center" sx={{ mt: 2 }}>
        {filteredEvents.length > 0 ? (
          filteredEvents.map((event) => (
            <Grid item xs={10} sm={5} md={3} key={event.id}>
              <EventThumbnail event={event} />
            </Grid>
          ))
        ) : (
          <EmptyEventPanel loading={loading} />
        )}
      </Grid>
    </EventsMainContainer>
  );
};
