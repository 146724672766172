import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { faqs } from "./constants";
import { FaqsWrapper } from "./styles";

export function FAQ() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Container
      id="faq"
      sx={{
        ...FaqsWrapper,
        backgroundColor: "#090E10", // Dark background for the entire section
        padding: "40px",
        borderRadius: "8px",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)",
      }}
    >
      <Typography
        component="h2"
        variant="h4"
        color="white" // White text for the title
        sx={{
          width: { sm: "100%", md: "60%" },
          textAlign: { sm: "left", md: "center" },
          fontFamily: "Inter, Sans-serif",
          marginBottom: "20px",
        }}
      >
        Frequently Asked Questions
      </Typography>
      <Box sx={{ width: "100%" }}>
        {faqs.map((faq, index) => (
          <Accordion
            key={index}
            expanded={expanded === `panel${index}`}
            onChange={handleChange(`panel${index}`)}
            sx={{
              backgroundColor: "#3c3c3c", // Dark grey background for each Accordion
              color: "white", // White text for the questions
              marginBottom: "10px",
              "&:before": {
                display: "none", // Remove the default divider line
              },
              "&.Mui-expanded": {
                margin: 0,
              },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
              aria-controls={`panel${index}d-content`}
              id={`panel${index}d-header`}
              sx={{
                borderBottom: "1px solid #4a4a4a", // Darker border for the summary
                fontFamily: "Inter, Sans-serif",
                fontSize: "18px", // Increase font size for questions
              }}
            >
              <Typography component="h3" variant="subtitle1" fontFamily="Inter, Sans-serif">
                {faq.question}
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                fontFamily: "Inter, Sans-serif",
                backgroundColor: "#4a4a4a", // Slightly darker background for the details
                color: "#e0e0e0", // Light grey text for the answers
                fontSize: "20px", // Font size for answers
                padding: "20px", // Add some padding for better readability
              }}
            >
              <Typography variant="body2" gutterBottom fontFamily="Inter, Sans-serif" fontSize="16px">
                {faq.answer}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </Container>
  );
}

export default FAQ;
