import * as React from "react";
import Box from "@mui/material/Box";
import { keyframes } from "@mui/system";
import { darkLogos, whiteLogos } from "./constants";
import { useTheme } from "@mui/material/styles";

const scroll = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
`;

export function LogoCollection() {
  const theme = useTheme();
  const logos = theme.palette.mode === "light" ? darkLogos : whiteLogos;
  const allLogos = [...logos, ...logos, ...logos, ...logos, ...logos, ...logos, ...logos, ...logos]; // Triple the logos

  return (
    <Box
      id="logoCollection"
      sx={{
        overflow: "hidden",
        whiteSpace: "nowrap",
        py: 4,
        position: "relative",
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          animation: `${scroll} 40s linear infinite`,
          alignItems: "center",
          width: "200%", // Make it twice as wide to fit all duplicated logos

        }}
      >
        {allLogos.map((logo, index) => (
          <Box
            component="img"
            src={logo}
            alt={`Company logo ${index + 1}`}
            key={index}
            sx={{
              height: 70, // Adjust logo height as needed
              mx: 3, // Adjust space between logos as needed
            }}
          />
        ))}
      </Box>
    </Box>
  );
}
