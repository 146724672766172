import React from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import {
  LandingHeader,
  FAQ,
  Footer,
  HeroSection,
  LogoCollection,
  Pricing,
  Testimonials,
} from "../components";

import { AIAgents } from "../components/landing/AIAgents.js";
import { VideoSection } from "../components/landing/VideoSection.js";
import { lightTheme, darkTheme } from './Theme.js'; // Import your themes
import { ThemeProvider } from '@mui/material/styles';


function LightSection() {
  return (
    <ThemeProvider theme={lightTheme}>
            <div style={{ backgroundColor: lightTheme.palette.background.default, color: lightTheme.palette.text.primary }}>

     
      <Box sx={{ bgcolor: "background.default" }}>
       
        <AIAgents />
        <Divider />
        <Testimonials />
        <Divider />
        {/* <Highlights /> */}
        <Divider />
       <Pricing/>
        <Divider />
       
      </Box>
      </div>
    </ThemeProvider>
  );
}

function DarkSection() {
  return (
    <ThemeProvider theme={darkTheme}>
            <div style={{ backgroundColor: darkTheme.palette.background.default, color: darkTheme.palette.text.primary }}>
      <LandingHeader />
      <HeroSection />
      <VideoSection />
      <LogoCollection />
      </div>
    </ThemeProvider>
  );
}

function DarkSection2() {
  return (
    <ThemeProvider theme={darkTheme}>
            <div style={{ backgroundColor: darkTheme.palette.background.default, color: darkTheme.palette.text.primary }}>
            <FAQ />
        <Divider />
        <Footer />
      </div>
    </ThemeProvider>
  );
}
export default function LandingPage() {
  return (
    <div>
      
      
      <DarkSection />
      <LightSection />
      <DarkSection2 />

    </div>
  );
}
