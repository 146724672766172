import React from "react";
import { VideoContainer, VideoBox } from "./styles";

export function VideoSection() {
  return (
    <VideoContainer>
      <VideoBox>
        <iframe
          id="video__player"
          width="100%"
          height="100%"
          src="https://s3.us-east-1.amazonaws.com/allurt.co/b2bconnect-linkedin-agent.mp4"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          style={{
            marginLeft: "auto",
            marginRight: "auto",
          }}
        ></iframe>
      </VideoBox>
    </VideoContainer>
  );
}

export default VideoSection;