import Box from "@mui/material/Box";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import XIcon from "@mui/icons-material/X";
import FacebookIcon from "@mui/icons-material/Facebook";
import Avatar from "@mui/material/Avatar";
import { deepOrange } from "@mui/material/colors";

export const EventTableColumns = [
  {
    field: "name",
    headerName: "Name",
    width: 200,
    valueGetter: (params) =>
      `${params.row?.FirstName || ""} ${params.row?.LastName || ""}`,
    renderCell: (params) => {
      const firstName = params.row?.FirstName ?? "";
      const lastName = params.row?.LastName ?? "";
      const fullName = `${firstName} ${lastName}`;
      return (
        <Box display="flex" alignItems="center">
          <Avatar
            src={params.row.Avatar}
            alt={fullName}
            sx={{
              bgcolor: params.row.Avatar ? "transparent" : deepOrange[500],
              marginRight: 1,
            }}
          >
            {!params.row.Avatar && firstName && lastName
              ? `${firstName[0]}${lastName[0]}`
              : ""}
          </Avatar>
          {fullName}
        </Box>
      );
    },
  },
  { field: "Email", headerName: "Email", width: 200 },
  { field: "Title", headerName: "Title", width: 150 },

  { field: "City", headerName: "City", width: 80 },
  { field: "State", headerName: "State", width: 80 },
  { field: "Country", headerName: "Country", width: 150 },
  { field: "Description", headerName: "Description", width: 300 },
  { field: "companyName", headerName: "Company Name", width: 170 },
  { field: "companyWebsite", headerName: "Company Website", width: 150 },
  {
    field: "LinkedIn",
    headerName: "LinkedIn",
    width: 90,
    renderCell: (params) => (
      <a href={params.value} target="_blank" rel="noopener noreferrer">
        <LinkedInIcon />
      </a>
    ),
  },
  {
    field: "Twitter",
    headerName: "Twitter",
    width: 90,
    renderCell: (params) => (
      <a href={params.value} target="_blank" rel="noopener noreferrer">
        <XIcon />
      </a>
    ),
  },
  {
    field: "Facebook",
    headerName: "Facebook",
    width: 90,
    renderCell: (params) => (
      <a href={params.value} target="_blank" rel="noopener noreferrer">
        <FacebookIcon />
      </a>
    ),
  },
  {
    field: "conferenceRole",
    headerName: "Conference Role",
    width: 150,
  },
];
