import * as React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" mt={1} fontFamily= "Inter, Sans-serif">
      {"Copyright © "}
      B2BConnect&nbsp;
      {new Date().getFullYear()}
    </Typography>
  );
}

export function Footer() {
  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 4, sm: 6 },
        py: { xs: 4, sm: 6 },
        textAlign: { sm: "center", md: "center" },
        backgroundColor: (theme) => theme.palette.background.default, // Matches the theme background
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center", // Centers the content horizontally
          justifyContent: "center", // Centers the content vertically
          width: "100%",
          textAlign: "center",
        }}
      >
        <div>
          <Copyright />
        </div>
      </Box>
    </Container>
  );
}
