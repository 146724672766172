import React from "react";
import { TextField } from "@mui/material";

export function FieldText({
  name,
  formValues,
  handleInputChange,
  errors,
  rows,
  type = "text",
  ...rest
}) {
  return (
    <TextField
      margin="dense"
      id={name}
      name={name}
      type={type}
      fullWidth
      variant="outlined"
      value={formValues[name]}
      onChange={handleInputChange}
      error={Boolean(errors[name])}
      helperText={errors[name]}
      multiline={!!rows}
      rows={rows}
      {...rest}
    />
  );
}
