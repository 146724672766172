import React, { useState } from "react";
import { Button, Typography } from "@mui/material";
import { EnrichmentAgentSelector } from "./components/EnrichmentAgentSelector";

export const UploadFileBtn = ({ showText }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);

  return (
    <div>
      <Button
        variant={showText ? "text" : "contained"}
        color="primary"
        onClick={handleOpen} // Opens the dialog when clicked
        sx={{ display: "flex", gap: 1 }}
      >
        <Typography variant="h5" fontWeight={400}>
          +
        </Typography>
        Select an agent
      </Button>
      {open && <EnrichmentAgentSelector setOpen={setOpen} />}
    </div>
  );
};
