import React, { useMemo } from "react";
import { Box, Typography } from "@mui/material";
import { startCase } from "lodash";

export const StatusBadge = ({ status, is_completed }) => {
  const color = useMemo(
    () => (is_completed ? "#66bb6a" : "#ffa826"),
    [is_completed]
  );

  return (
    <Box
      sx={{
        position: "relative",
        display: "inline-flex",
        backgroundColor: `${color}10`,
        paddingX: 1,
        paddingY: 0.5,
        borderRadius: 1,
        border: `1px solid ${color}`,
      }}
    >
      <Typography variant="body1" color={color} fontSize={12} fontWeight={900}>
        {startCase(status)}
      </Typography>
    </Box>
  );
};
