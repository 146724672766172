import * as React from "react";
import { useTheme } from "@mui/system";
import { darkLogos, userTestimonials, whiteLogos } from "./constants";
import {
  TestimonialBox,
  TestimonialBoxStyle,
  TestimonialCard,
  TestimonialContainer,
  TestimonialContainerStyle,
  TestimonialLogoStyle,
  CardContent,
} from "./styles";
import { Box, CardHeader, Grid, Typography } from "@mui/material";

export function Testimonials() {
  const theme = useTheme();
  const logos = theme.palette.mode === "light" ? darkLogos : whiteLogos;

  return (
    <TestimonialContainer id="testimonials" sx={TestimonialContainerStyle}>
      <Box sx={TestimonialBoxStyle}>
        <Typography
          component="h2"
          variant="h3"
          align="center"
          color="text.primary"
          sx={{
            fontFamily: "Inter, Sans-serif",
            paddingBottom: "15px",
            fontWeight: "bold",
            wordBreak: "break-word",
          }}
        >
          Testimonials
        </Typography>
        <Typography
          variant="body1"
          color="text.secondary"
          component="p"
          fontFamily="Inter, Sans-serif"
          sx={{
            fontSize: "18px",
            lineHeight: "1.5",
            color: "grey",
            textAlign: "center",
            paddingBottom: "50px",
            paddingTop: "0px",
            maxWidth: "600px",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          See what our customers love about our products. Discover how we excel
          in efficiency, durability, and satisfaction.
        </Typography>
      </Box>
      <Grid container spacing={3}>
        {" "}
        {/* Increase spacing between cards */}
        {userTestimonials.map((testimonial, index) => (
          <Grid item xs={12} sm={6} lg={4} key={index} sx={{ display: "flex" }}>
            <TestimonialCard
              sx={{
                backgroundColor:
                  theme.palette.mode === "light" ? "#f9f9f9" : "#333", // Lighter background for light mode
                boxShadow: theme.shadows[3], // Add some shadow for depth
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <CardContent>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  fontFamily="Inter, Sans-serif"
                  fontSize="16px"
                >
                  {testimonial.testimonial}
                </Typography>
              </CardContent>
              <TestimonialBox>
                <CardHeader
                  avatar={testimonial.avatar}
                  title={testimonial.name}
                  subheader={testimonial.occupation}
                  id="customers__info"
                  sx={{
                    fontFamily: "Inter, Sans-serif",
                    fontSize: "16px",
                    padding: "0px",
                  }}
                />
                <img
                  src={logos[index]}
                  alt={`Logo ${index + 1}`}
                  style={TestimonialLogoStyle}
                />
              </TestimonialBox>
            </TestimonialCard>
          </Grid>
        ))}
      </Grid>
    </TestimonialContainer>
  );
}
