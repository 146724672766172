import { Avatar } from "@mui/material";

export const whiteLogos = [
  "./logos/AI Artisan-light.svg",
  "./logos/foundersuite-light.svg",
  "./logos/somacap-light.svg",
  "./logos/inpeak-light.svg",
  "./logos/nixsheets-light.svg",
  "./logos/begig-light.svg",
];

export const darkLogos = [
  "./logos/AI Artisan-dark.svg",
  "./logos/foundersuite-dark.svg",
  "./logos/somacap-dark.svg",
  "./logos/inpeak-dark.svg",
  "./logos/nixsheets-dark.svg",
  "./logos/begig-dark.svg",
];

export const userTestimonials = [
  {
    avatar: <Avatar alt="Jaspar Carmichael-Jack" src="./logos/jaspar.png" />,
    name: "Jaspar Carmichael-Jack",
    occupation: "CEO",
    testimonial:
      "b2bconnect.ai has transformed how we network at tech events. It's the key to unlocking meaningful connections and has significantly boosted our conference ROI. Absolutely indispensable for us!",
  },
  {
    avatar: <Avatar alt="Nathan Beckord" src="./logos/nathan.png" />,
    name: "Nathan Beckord",
    occupation: "CEO",
    testimonial:
      "This platform is a game-changer for startups seeking investment. b2bconnect.ai has enabled us to meet potential investors at conferences like never before. A true asset for our growth!",
  },
  {
    avatar: <Avatar alt="Mir Faiyaz" src="./logos/mir faiyaz.png" />,
    name: "Mir Faiyaz",
    occupation: "Investor",
    testimonial:
      "b2bconnect.ai has revolutionized our scouting process at industry events, connecting us with promising startups efficiently. It's a powerful tool for anyone in the investment space.",
  },
  {
    avatar: <Avatar alt="Somi Arian" src="./logos/somi.png" />,
    name: "Somi Arian",
    occupation: "CEO",
    testimonial:
      "b2bconnect.ai offers unparalleled insights into conference attendees, enabling us to engage with the right contacts. It's become a cornerstone of our event strategy and networking success.",
  },
  {
    avatar: <Avatar alt="Sai M. Abdulla" src="./logos/sai m.png" />,
    name: "Sai M. Abdulla",
    occupation: "CEO",
    testimonial:
      "The data enrichment from b2bconnect.ai has opened up new business opportunities for us at financial conferences. It's a game-changer for networking and expanding our professional network.",
  },
  {
    avatar: <Avatar alt="Cindy Baker" src="./logos/shilpa.png" />,
    name: "Shilpa Jain",
    occupation: "CEO",
    testimonial:
      "Finding potential clients at professional gatherings has never been easier, thanks to b2bconnect.ai. It's an essential tool for growing our freelancer community and uncovering unique opportunities.",
  },
];

export const pricingTiers = [
  {
    title: "Hobby",
    price: "$29",
    priceunit: "",
    description: [
      "2000 credits",
      "2 concurrent agents",
      "Max 200 CSV entries",
    ],
    buttonText: "Sign up for free",
    buttonVariant: "outlined",
  },
  {
    title: "Startup",
    // subheader: "Recommended",
    price: "$189",
    priceunit: "",
    description: [
      "15,000 credits",
      "5 concurrent agents",
      "Max 1000 CSV entries",
    ],
    buttonText: "Start now",
    // buttonVariant: "contained",
    buttonVariant: "outlined",
  },
  {
    title: "Growth",
    price: "$699",
    subheader: "Recommended",
    priceunit: "",
    description: [
      "60,000 credits",
      "10 concurrent agents",
      "Max 5000 CSV entries",
    ],
    buttonText: "Start now",
    buttonVariant: "contained",
  },
];

export const faqs = [
  {
    question:
      "What are AI Go-To-Market Agents, and how do they help grow my business?",
    answer:
      "Our AI GTM Agents are designed to automate key growth-related tasks like finding business emails (coming soon), enriching LinkedIn data, and identifying ideal customer profiles (coming soon). These agents streamline your workflow, allowing you to focus on growth while they handle the heavy lifting, helping you scale faster.",
  },
  {
    question: "How do the 30 free credits work when I sign up?",
    answer:
      "When you sign up, you receive 30 free credits, which can be used across all of our AI Agents. This allows you to test our services without needing a credit card. You can upgrade to a paid plan once your free credits are used.",
  },
  {
    question:
      "What does 'concurrent agents' mean?",
    answer:
      "Concurrent agents refer to the number of CSV rows that can be processed simultaneously by our AI agents. For example, if you have 5 concurrent agents, 5 CSV rows will be enriched at the same time, speeding up data processing.",
  },
  {
    question:
      "Is my data safe with your platform? ",
    answer:
      "Yes, your data is securely handled, and we delete all uploaded data from our system within 30 days, ensuring privacy and compliance with data protection regulations.",
  },
  {
    question: "Can I integrate your services into my existing workflows?",
    answer:
      "Yes! While some APIs are still in development, you will soon be able to integrate our Email Finder, LinkedIn Enrichment, and Prospecting Agents into your workflows using APIs for seamless automation.",
  },
  {
    question:
      "How do I upgrade from free credits to a paid plan?",
    answer:
      "Once you’ve used up your free credits, you can easily purchase more credits by choosing from one of our paid plans: Hobby, Startup, or Growth. Each plan provides different amounts of credits, concurrent agents, and CSV entry limits, tailored to fit your needs.",
  },
];
