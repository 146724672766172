import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { PricingCard } from "./PricingCard";
import { pricing } from "./constants";

export function PlanCards({ data, ...props }) {
  const [currentPlan, setCurerntPlan] = useState();

  useEffect(() => {
    if (data) {
      const { base_price } = data;
      const currentPlan = pricing.find((plan) => plan.price === base_price);
      setCurerntPlan(currentPlan)
    }
  }, [data]);

  return (
    <Box display="flex" alignItems="center" sx={{ flexFlow: "wrap" }} gap={2}>
      {pricing.map((priceCard) => (
        <PricingCard
          {...props}
          data={priceCard}
          key={`plan card ${priceCard.name}`}
          selectedPlan={currentPlan}
        />
      ))}
    </Box>
  );
}
