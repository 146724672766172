import React from "react";
import { AuthPanel } from "./AuthPanel";
import { ProfileDetail } from "./ProfileDetail";
import { getProfile } from "../common/constants";
import { UseAuth } from "./AuthContext";

export function Profile(props) {
  const { checkIsLoggedIn } = UseAuth();
  if (!checkIsLoggedIn()) return <AuthPanel {...props} />;
  return <ProfileDetail {...props} userInfo={getProfile()} />;
}
