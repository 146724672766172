import React from "react";
import { FieldText } from "./TextField";
import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";

export function CreatePasswordForm({
  handleSubmit,
  email,
  loading,
  ...commonProps
}) {
  return (
    <>
      <DialogTitle id="form-dialog-title">Create Password</DialogTitle>
      <DialogContent sx={{ py: 0 }}>
        <DialogContentText>
          <b>Create password for email:</b> ({email})
        </DialogContentText>
        <FieldText
          {...commonProps}
          name="password"
          label="Password"
          type="password"
        />
      </DialogContent>
      <DialogActions>
        <Box
          display="flex"
          padding={2}
          justifyContent="center"
          width="100%"
          alignItems="center"
          flexDirection="column"
          gap={2}
        >
          <Button
            onClick={handleSubmit}
            color="primary"
            variant="contained"
            size="large"
            sx={{ px: 2, py: 1 }}
          >
            <Typography>Create Password</Typography>
            {loading && (
              <CircularProgress
                color="inherit"
                size={16}
                sx={{ marginLeft: 1 }}
              />
            )}
          </Button>
        </Box>
      </DialogActions>
    </>
  );
}
