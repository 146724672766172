import React, { useContext, useEffect, useMemo, useState } from "react";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import { axiosInstant, logoutUser } from "../common/api";
import { Box, Button, MenuItem, Typography } from "@mui/material";
import { ToggleColorMode } from "../landing/header/ToggleColorMode";
import { ThemeContext } from "../../theme";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getProfile, goToSettingsTab, Settings } from "../common/constants";
import { CurrencyExchange } from "@mui/icons-material";

export function ProfileDetail(props) {
  const { pathname } = useLocation();
  const userInfo = getProfile();
  const navigate = useNavigate();
  const { mode } = useContext(ThemeContext);
  const { isLandingPage = false, isMobileView = false } = props;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const company = useMemo(() => userInfo.company?.slice(0, 20), [userInfo]);
  const name = useMemo(
    () => userInfo.name?.split(" ", 2).join(" ")?.slice(0, 16),
    [userInfo]
  );

  const [credits, setCredits] = useState(0);

  useEffect(() => {
    axiosInstant("/user/me")
      .then(({ data: res }) => setCredits(res.credits))
      .catch((err) => console.log(err?.message));
  }, []);

  return (
    <Box display="flex" gap={3} alignItems="center">
      {!pathname.includes("event") && (
        <Box display="flex" alignItems="center" gap={2}>
          <Typography display="flex" gap={1} alignItems="center">
            <CurrencyExchange sx={{ fontSize: 14 }} />
            {credits} credits
          </Typography>
          <Link variant="outlined" to={goToSettingsTab(Settings.Billing)}>
            <Button variant="outlined" sx={{ color: "#fff" }}>
              Upgrade
            </Button>
          </Link>
        </Box>
      )}
      <Box display="flex" onClick={handleClick} sx={{ cursor: "pointer" }}>
        <Avatar>{userInfo.name?.[0]?.toUpperCase()}</Avatar>
        <Box marginLeft={2} textAlign="left" color="#fff">
          <Typography
            color={isLandingPage ? "text.primary" : "text.default"}
            fontSize={16}
            textTransform="capitalize"
          >
            {name}
          </Typography>
          <Typography
            color={isLandingPage ? "text.primary" : "text.default"}
            fontSize={12}
          >
            {company}
          </Typography>
        </Box>
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{ top: 10 }}
        onClick={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {!isMobileView && (
          <MenuItem display="flex" sx={{ alignItems: "center", gap: 4 }}>
            <Typography>{!mode ? "Light" : "Dark"} Mode</Typography>
            <ToggleColorMode />
          </MenuItem>
        )}
        <MenuItem onClick={() => navigate("/settings")}>Settings</MenuItem>
        <MenuItem onClick={logoutUser}>Logout</MenuItem>
      </Menu>
    </Box>
  );
}
