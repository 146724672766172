import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { UseAuth } from "../profile/AuthContext";
import { GoBackBtn } from "../common/GoBackBtn";
import { startCase } from "lodash";
import {
  Container,
  Wrapper,
  WrapperStyle,
  StepBelt,
  PanelBody,
} from "./components";
import { EnrichTypes } from "../enrichment/components/constants";

const getTitlesByEnrichmentType = (enrichmentType) => {
  if (enrichmentType === EnrichTypes.Linkedin) {
    return {
      heroDescription: "The LinkedIn Enrichment Agent helps you extract latest information on anyone's profile. Please provide a CSV file containing LinkedIn URLs"
    }
  }
  return null;
}

export const Enrichment = (props) => {
  const { type } = props;
  const [step, setStep] = useState(0);
  const { showLoginPopup, checkIsLoggedIn } = UseAuth();
  const [heroDescription, setHeroDescription] = useState("");

  useEffect(() => {
    if (!checkIsLoggedIn()) showLoginPopup(true);
    const labelsObj = getTitlesByEnrichmentType(type);
    setHeroDescription(labelsObj.heroDescription);
  }, []);

  return (
    <Container>
      <Wrapper {...WrapperStyle}>
        <Box marginTop={5}>
          <GoBackBtn />
          <Typography variant="h5">AI {startCase(type)} Enrichment</Typography>
        </Box>
        <Box marginTop={0} display="flex" flexDirection="column" gap={4}>
          <Typography variant="h6">{heroDescription}</Typography>
          <StepBelt step={step} />
          <PanelBody
            {...props}
            nextStep={() => setStep(step + 1)}
            prevStep={() => setStep(step - 1)}
          />
        </Box>
      </Wrapper>
    </Container>
  );
};
