import React from "react";
import {
  Typography,
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardActions,
  Chip
} from "@mui/material";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty"; // Importing the "Coming Soon" icon

export const EnrichmentAgent = ({
  title,
  description,
  icon,
  onClick,
  isComingSoon,
  isAvailable,
  creditsPerCycle
}) => (
  <Card
    sx={{
      width: "100%",
      borderRadius: 2,
      overflow: "hidden",
      backgroundColor: "#d8dbe2",
      display: "flex",
      flexDirection: "column", // Ensuring proper layout
    }}
  >
    <CardActionArea sx={{ flexGrow: 1 }} onClick={onClick}>
      <CardContent
        sx={{
          padding: 2,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          textAlign="left"
          marginBottom={1}
        >
          {isComingSoon ? (
            <Box display="flex" justifyContent="center" marginBottom={1}>
              <HourglassEmptyIcon sx={{ fontSize: 48, color: "#9e9e9e" }} />
            </Box>
          ) : (
            <>
              <Box display="flex" alignItems="center" marginBottom={1}>
                {icon}
                <Typography
                  variant="h6"
                  color="#000"
                  sx={{
                    textAlign: "left",
                    marginLeft: 1,
                    fontFamily: "Arial, sans-serif",
                    fontWeight: "bold",
                    fontSize: "1rem",
                  }} // Heading styling
                >
                  {title}
                </Typography>
              </Box>
              {description && (
                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={{
                    marginTop: 0.5,
                    lineHeight: 1.5,
                    textAlign: "left",
                    fontFamily: "Arial, sans-serif",
                    fontSize: "0.9rem",
                    display: "block",
                    width: "100%",
                  }} // Description styling
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              )}
            </>
          )}
        </Box>
      </CardContent>
    </CardActionArea>

    <CardActions style={{paddingLeft: "15px"}}>
      {isAvailable && <Chip size="small" label={`${creditsPerCycle} crds/cycle`} variant="outlined" />}
      <Chip size="small" label={isAvailable ? "Available" : "Coming Soon"} variant="outlined" color="primary" />
    </CardActions>
  </Card>
);
