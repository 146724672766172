import React, { useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { axiosInstant } from "../common/api";

export function CancelSubscriptionPopup({ onClose }) {
  const [loading, setLoading] = useState(false);

  const cancelSubscription = () => {
    setLoading(true);
    axiosInstant
      .post("/user/cancel-subscription", {})
      .then(() => {
        setLoading(false);
        window.location.reload();
      })
      .catch((err) => console.log(err?.message));
  };
  return (
    <Dialog
      open
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      disableEscapeKeyDown
      sx={{ backdropFilter: "blur(3px)" }}
    >
      <DialogTitle>Cancel Subscription</DialogTitle>
      <DialogContent>
        <Typography variant="body2">
          Are you sure, you want to cancel your subcription ?
        </Typography>
        <Typography variant="body2">
          After cancellation, you will still be able to use your remaining
          credits till its expiry date
        </Typography>
      </DialogContent>
      <DialogActions>
        <Box
          display="flex"
          padding={2}
          justifyContent="flex-end"
          width="100%"
          alignItems="center"
          gap={2}
        >
          <Button
            onClick={cancelSubscription}
            color="primary"
            variant="contained"
            sx={{ px: 2, py: 1 }}
          >
            <Typography>Cancel Subscription</Typography>
            {loading && (
              <CircularProgress
                color="inherit"
                size={16}
                sx={{ marginLeft: 1 }}
              />
            )}
          </Button>
          <Button
            onClick={onClose}
            color="secondary"
            variant="outlined"
            sx={{ minWidth: "fit-content" }}
          >
            Close
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
