import React from "react";
import { TextField } from "@mui/material"; // Use MUI TextField component
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  CircularProgress,
} from "@mui/material";
import { AuthError } from "./AuthError"; // Error handling component, adjust if necessary

export function LoginForm({
  formValues, // Form values for the inputs
  handleInputChange, // Function to handle form changes
  handleSubmit, // Function to handle form submission
  loading, // Loading state
  errors, // Validation errors
  onRegister, // Function to switch to sign-up form
}) {
  return (
    <>
      <DialogTitle id="form-dialog-title">Sign In</DialogTitle>
      <DialogContent sx={{ py: 0 }}>
        <DialogContentText>
          Let us know about you and the kind of people you want to connect with.
          This helps us serve you better.
        </DialogContentText>

        {/* Email Field */}
        <TextField
          name="email"
          label="Email Address"
          value={formValues.email} // Pass value from formValues
          onChange={handleInputChange} // Handle input changes
          error={Boolean(errors.email)} // Show error if any
          helperText={errors.email} // Display validation message
          fullWidth
          margin="normal"
        />

        {/* Password Field */}
        <TextField
          name="password"
          label="Password"
          type="password"
          value={formValues.password} // Pass value from formValues
          onChange={handleInputChange} // Handle input changes
          error={Boolean(errors.password)} // Show error if any
          helperText={errors.password} // Display validation message
          fullWidth
          margin="normal"
        />

        {/* Error component if necessary */}
        {errors && <AuthError errors={errors} />}
      </DialogContent>

      <DialogActions>
        <Box
          display="flex"
          padding={2}
          justifyContent="center"
          width="100%"
          alignItems="center"
          flexDirection="column"
          gap={2}
        >
          {/* Submit Button */}
          <Button
            onClick={handleSubmit}
            color="primary"
            variant="contained"
            size="large"
            sx={{ px: 2, py: 1 }}
            disabled={loading} // Disable button while loading
          >
            <Typography>Sign In</Typography>
            {loading && (
              <CircularProgress
                color="inherit"
                size={16}
                sx={{ marginLeft: 1 }}
              />
            )}
          </Button>

          {/* Switch to Sign Up button */}
          <Button
            onClick={onRegister} // Call the toggle function to switch to Sign Up
            color="secondary"
            variant="text"
            size="large"
            sx={{ minWidth: "fit-content" }}
          >
            Create a new account
          </Button>
        </Box>
      </DialogActions>
    </>
  );
}
