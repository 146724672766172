import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { EventTableColumns } from "./TableColumns";

export function EventTable({ tableRows }) {
  return (
    <DataGrid
      sx={{ height: "70%" }}
      rows={tableRows}
      columns={EventTableColumns}
      components={{
        Toolbar: GridToolbar,
      }}
      initialState={{
        pagination: {
          paginationModel: { page: 0, pageSize: 15 },
        },
      }}
      pageSizeOptions={[15, 25, 50, 100]}
      checkboxSelection
    />
  );
}
