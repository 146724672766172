import React, { useEffect, useState } from "react";
import {
  TableColumns as Column,
  EnrichTypes,
  getTableColumnWidth,
  isLinkedinUrl,
  tableColumnProps,
} from "./constants";
import { ProcessedDataView } from "./ProcessedDataView";
import { isUrl } from "../../common/constants";
import { startCase } from "lodash";

export const ShowProcessedData = ({ type, data, mapping, ...rest }) => {
  const [validData, setValidData] = useState();
  const [invalidData, setInvalidData] = useState();
  const [tableColumns, setTableColumns] = useState([]);

  const getFormattedObj = (data) => {
    switch (type) {
      case EnrichTypes.Email:
        return {
          [Column.FirstName]: data[mapping[Column.FirstName]],
          [Column.LastName]: data[mapping[Column.LastName]],
          [Column.Company]: data[mapping[Column.Company]],
        };

      case EnrichTypes.Linkedin:
        return { [Column.Linkedin]: data[mapping[Column.Linkedin]] };

      default:
        return {};
    }
  };

  useEffect(() => {
    const validDataArray = [];
    const invalidDataArray = [];
    data?.forEach((data) => {
      const dataObj = getFormattedObj(data);
      const isValidObj = Object.values(dataObj).every((e) => e);
      const isValidUrl =
        (type === EnrichTypes.Linkedin &&
          isLinkedinUrl(dataObj[Column.Linkedin])) ||
        (type === EnrichTypes.Email && isUrl(dataObj[Column.Company]));
      if (isValidObj && isValidUrl) validDataArray.push({ ...data });
      else invalidDataArray.push({ ...data });
    });
    setValidData(
      validDataArray.map((e, index) => ({ ...e, id: e.id || `valid-${index}` }))
    );
    setInvalidData(
      invalidDataArray.map((e, index) => ({
        ...e,
        id: e.id || `invalid-${index}`,
      }))
    );
    const headers = Object.keys(data[0]).map((field) => ({
      ...tableColumnProps,
      field,
      headerName: startCase(field),
      minWidth: getTableColumnWidth(field),
    }));
    setTableColumns(headers);
  }, [data, mapping]);

  const props = {
    ...rest,
    validData,
    invalidData,
    totalRows: data.length,
    tableColumns,
  };
  return <ProcessedDataView {...props} />;
};
