import {
  Box,
  styled,
  Switch,
  TableCell,
  tableCellClasses,
  TableRow,
  Typography,
} from "@mui/material";
import { formatDistanceToNow } from "date-fns";
import { startCase } from "lodash";
import { isUrl } from "../../common/constants";
import { SuccessRatio } from "./SuccessRatio";
import { StatusBadge } from "./StatusBadge";
import { HistoryTableActions } from "./HistoryTableActions";

export const EnrichTypes = {
  Email: "email",
  Linkedin: "linkedin",
};

export const Container = styled("div")(() => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
}));

export const MainWrapper = styled(Box)(() => ({
  padding: 20,
  display: "flex",
}));

export const MainWrapperStyle = {
  sx: {
    width: { xs: "100%", md: 1000 },
    flexDirection: { xs: "column", sm: "row" },
  },
};

export const HistoryTable = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  padding: "40px 0",
  width: "100%",
}));

export const HistoryTableStyle = {
  sx: {
    width: { xs: "100%", md: 1000 },
  },
};

export const OptionContainer = styled("div")(() => ({
  display: "flex",
  padding: 10,
  alignItems: "center",
  gap: 30,
  justifyContent: "space-between",
  border: "1px solid #888",
  borderRadius: 10,
  cursor: "pointer",
  ":hover": {
    backgroundColor: "#f0f0f0",
  },
}));

export const OptionTextWraper = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  gap: 2,
}));

export const tableColumnProps = {
  flex: 1,
  disableColumnMenu: true,
  minWidth: 150,
  align: "center",
  headerAlign: "center",
  sortable: false,
};

export const getTableColumnWidth = (field) =>
  ["linkedin", "website", "url"].some(
    (e) => field.toLowerCase().indexOf(e) >= 0
  )
    ? 350
    : 150;

export const EnrichmentTableColumns = [
  {
    ...tableColumnProps,
    field: "name",
    headerName: "File Name",
    minWidth: 200,
    align: "left",
    headerAlign: "left",
    sortable: true,
  },
  {
    ...tableColumnProps,
    field: "status",
    headerName: "Job Status",
    renderCell: (params) => <StatusBadge {...params.row} />,
  },
  {
    ...tableColumnProps,
    field: "total",
    headerName: "Scanned rows",
  },
  {
    ...tableColumnProps,
    field: "createdAt",
    headerName: "Created Time",
  },
  {
    ...tableColumnProps,
    field: "enrich_percentage",
    headerName: "Percentage completion",
    renderCell: (params) => (
      <SuccessRatio value={params.row.enrich_percentage || 0} />
    ),
  },
  {
    ...tableColumnProps,
    field: "actions",
    type: "actions",
    minWidth: 200,
    renderCell: (params) => <HistoryTableActions {...params.row} />,
  },
];

export const getFormattedHistory = (history) => {
  if (!history?.length) return history;
  return history.map((row) => ({
    ...row,
    createdAt: formatDistanceToNow(new Date(row.createdAt), {
      addSuffix: true,
    }),
    status: startCase(row.status),
    total: row.total_rows,
  }));
};

export const Wrapper = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  gap: 30,
  padding: 20,
}));

export const WrapperStyle = {
  sx: {
    width: { xs: "100%", md: 1000 },
  },
};

export const UploadFilePanel = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: 10,
  padding: 20,
  border: "1px solid",
  borderRadius: 5,
  alignItems: "center",
  backgroundColor: theme.palette.mode === "dark" ? "#000" : "#f0f0f0",
}));

export const BorderedText = styled(Typography)(() => ({
  padding: "5px 10px",
  borderRadius: 20,
  border: "1px solid",
}));

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: "#fff",

  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export const getFileColumnNames = (data) => {
  if (data.length) return Object.keys(data[0]);
  return [];
};

export const isSpreadsheet = (type) =>
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" === type;

export const TableColumns = {
  FirstName: "firstName",
  LastName: "lastName",
  Company: "companyDomain",
  Linkedin: "linkedinUrl",
};

export const csvDefaultMappedState = (defaultVal = "") => ({
  [TableColumns.FirstName]: defaultVal,
  [TableColumns.LastName]: defaultVal,
  [TableColumns.Company]: defaultVal,
});

export const CsvTableMapping = [
  { field: TableColumns.FirstName, headerName: "First Name", minWidth: 200 },
  { field: TableColumns.LastName, headerName: "Last Name", minWidth: 200 },
  { field: TableColumns.Company, headerName: "Company Website", minWidth: 200 },
];

export const linkedinDefaultMappedState = (defaultVal = "") => ({
  [TableColumns.Linkedin]: defaultVal,
});

export const LinkedinTableMapping = [
  { field: TableColumns.Linkedin, headerName: "LinkedIn URL", minWidth: 500 },
];

export const defaultMappedState = (type, defaultVal = "") => {
  switch (type) {
    case EnrichTypes.Email:
      return {
        [TableColumns.FirstName]: defaultVal,
        [TableColumns.LastName]: defaultVal,
        [TableColumns.Company]: defaultVal,
      };

    case EnrichTypes.Linkedin:
      return {
        [TableColumns.Linkedin]: defaultVal,
      };

    default:
      return {};
  }
};

export const LinkedinRegex = new RegExp(
  "^https?://((www|ww).)?linkedin.com/((in/[^/]+/?)|(pub/[^/]+/((w|d)+/?){3}))$"
);

export const isLinkedinUrl = (url) => {
  if (!isUrl(url)) return false;
  return LinkedinRegex.test(url);
};

export function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
    sx: { textTransform: "none" },
  };
}

export const EnrichHistoryPollingSecs = 10000; // 10 seconds

export const tableStyles = (shouldShow, customCss) => ({
  sx: {
    height: "100%",
    width: "100%",
    fontFamily: "Arial, Sans-serif",
    fontSize: "14px",
    textAlign: "center",
    border: "1px solid #ccc", // Border around the entire table
    borderRadius: shouldShow ? 1 : 0,
    ...(customCss || {}),

    "& .MuiDataGrid-cell, & .MuiDataGrid-columnHeaders": {},
    "& .MuiDataGrid-columnHeaders": {
      borderBottom: shouldShow ? "1px solid #ccc" : 0, // Bottom border for headers
    },
    "& .MuiDataGrid-columnSeparator": {
      display: "none",
    },
    "& .MuiDataGrid-columnHeadersInner": {
      borderBottom: "1px solid #ccc", // Bottom border for headers
    },
    "& .MuiDataGrid-columnHeader": {},
    "& .MuiDataGrid-columnHeaderTitle": {
      fontWeight: "bold",
    },
    "& .MuiDataGrid-columnHeader-cellContent": {
      padding: 1,
    },
    "& .MuiDataGrid-virtualScroller": {},
    "& .MuiDataGrid-virtualScrollerContent": {
      display: shouldShow ? "block" : "none",
    },
    "& .MuiDataGrid-footerContainer": {
      display: shouldShow ? "block" : "none",
    },
    "& .MuiDataGrid-selectedRowCount": {
      display: "none",
    },
    "& .MuiDataGrid-cell:focus": {
      outline: "none",
    },
  },
  initialState: {
    pagination: {
      paginationModel: { page: 0, pageSize: 10 },
    },
  },
  pageSizeOptions: [10, 25, 50],
});

export const StyledChipArray = styled("div")(({ theme }) => ({
  display: "flex",
  gap: 16,
  flexWrap: "wrap",
  padding: 16,
  backgroundColor: theme.palette.mode === "dark" ? "#000" : "#f0f0f0",
  borderRadius: 4,
}));

export const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    width: 16,
    height: 16,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "#1890ff",
        ...theme.applyStyles("dark", {
          backgroundColor: "#177ddc",
        }),
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    margin: 0,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: "rgba(0,0,0,.25)",
    boxSizing: "border-box",
    ...theme.applyStyles("dark", {
      backgroundColor: "rgba(255,255,255,.35)",
    }),
  },
}));

export const RequiredFieldNames = {
  person: [
    "FirstName",
    "LastName",
    "Description",
    "Title",
    "City",
    "State",
    "Country",
    "Followers",

    "PersonFirstPost",
    "PersonSecondPost",
    "PersonThirdPost",
    "SchoolName",
    "PersonPhoneNumber",
    "PersonEmail",
  ],
  company: [
    "CompanyName",
    "CompanyWebsite",
    "CompanyPhone",
    "CompanyIndustry",
    "CompanyIndustryTopics",
    "CompanyDescription",
    "CompanyAddress",
    "CompanyCity",
    "CompanyState",
    "CompanyCountry",
    "CompanyRevenue",
    "CompanyLatestFunding",
    "CompanyLinkedIn",

    "NumberOfEmployees",
    "CompanyFirstRecentJob",
    "CompanyFollowers",
    "CompanySecondRecentJob",
    "CompanyThirdRecentJob",
    "CompanyFirstPost",
    "CompanySecondPost",
    "CompanyThirdPost",
  ],
};

export const getFilteredOptions = (options, removableOptions) =>
  [...options].filter((n) => !removableOptions.includes(n));

export const removeExistingField = (arr, word) => {
  const index = arr.findIndex((field) => field === word);
  if (index < 0) return arr;

  const temp = [...arr];
  temp.splice(index, 1);
  return temp;
};
