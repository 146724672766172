import React from "react";
import { Typography, useTheme } from "@mui/material";

export const EmptyEventPanel = ({ loading }) => {
  const theme = useTheme();

  return (
    <Typography
      variant="h6"
      sx={{
        mt: 4,
        color: theme.palette.mode === "dark" ? "#fff" : "#000",
      }}
    >
      {loading ? "Loading..." : "No events found"}
    </Typography>
  );
};
