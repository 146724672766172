import { Box, Divider, Typography } from "@mui/material";
import React, { useMemo } from "react";
import {
  CheckIcon,
  pricingBtnProps,
  PricingCardBtn,
  PricingCardStyle,
} from "./constants";
import { getProfile } from "../common/constants";

export function PricingCard({ isYearly, data, contact, selectedPlan }) {
  const actualPrice = useMemo(() => {
    if (data.text) return data.text;
    if (data.price) {
      if (isYearly) return data.price - Math.round(data.price / 10);
      return data.price;
    }
    return "";
  }, [isYearly, data]);

  const upgradePackage = () => {
    const id = isYearly ? data.yearlyPaymentId : data.monthlyPaymentId;
    const { email } = getProfile();
    const params = email ? `?prefilled_email=${email}` : "";
    const url = `https://buy.stripe.com/${id}${params}`;
    if (id) window.open(url, "_blank");
  };

  const btnText = useMemo(() => {
    const isSelected = data?.price === selectedPlan?.price;
    return (
      <PricingCardBtn $active={isSelected} {...pricingBtnProps(upgradePackage)}>
        {isSelected
          ? "Renew Plan"
          : data?.price < selectedPlan?.price
          ? "Downgrade"
          : "Upgrade"}
      </PricingCardBtn>
    );
  }, [data, selectedPlan, isYearly]);

  return (
    <PricingCardStyle>
      <Typography textTransform="uppercase">
        {isYearly && "Annual"} {data.name}
      </Typography>

      <Box height={20}>
        {isYearly && data.price && (
          <Typography
            sx={{
              textDecoration: "line-through",
              textDecorationColor: "#f00",
              color: "#a1b3c1",
            }}
          >
            ${data.price}
          </Typography>
        )}
      </Box>

      <Box display="flex" gap={1}>
        {data.price && (
          <Typography fontSize={20} marginTop={1}>
            $
          </Typography>
        )}
        <Typography fontSize={36} fontWeight="bold">
          {actualPrice}
        </Typography>
      </Box>

      <Typography color="#465c6f">
        per month{isYearly && data.price && ", billed annually"}
      </Typography>

      <Divider sx={{ marginY: 2 }} />

      <Box display="flex" flexDirection="column" gap={1} minHeight={150}>
        {data.perks.map((perk, index) => (
          <Box
            display="flex"
            gap={1}
            paddingX={1}
            alignItems="center"
            key={`pricing-${data.name}-perk-${index}`}
          >
            {data.price && <CheckIcon />}
            <Typography key={`plan perk ${data.name} - ${index}`}>
              {perk}
            </Typography>
          </Box>
        ))}
      </Box>

      <Box position="absolute" sx={{ inset: "auto 0 24px 0" }}>
        <Box display="flex" justifyContent="center" paddingX={2}>
          {data.price ? (
            btnText
          ) : (
            <PricingCardBtn {...pricingBtnProps(contact)}>
              Contact Sales
            </PricingCardBtn>
          )}
        </Box>
      </Box>
    </PricingCardStyle>
  );
}
