import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { SettingTab } from "./SettingTab";
import { AccountSettings } from "./AccountSettings";
import { PurchaseHistory } from "./PurchaseHistory";
import { useMediaQuery, useTheme } from "@mui/material";
import {
  getTabProps,
  SettingsContainer,
  SettingsDetails,
  SettingsTabStyle,
} from "./constants";
import { GoBackBtn } from "../common/GoBackBtn";
import { getProfile, Settings } from "../common/constants";
import { useSearchParams } from "react-router-dom";
import { BillingPlans } from "./BillingPlans";
import { axiosInstant } from "../common/api";

export const SettingsPage = () => {
  const theme = useTheme();
  const [searchParams] = useSearchParams();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));

  const [value, setValue] = React.useState();
  const [purchases, setPurchases] = React.useState();

  React.useEffect(() => {
    const tab = searchParams.get("tab");
    const selectedVal = Object.values(Settings).includes(tab)
      ? tab
      : Settings.Account;
    setValue(selectedVal);
  }, [searchParams]);

  React.useEffect(() => {
    const { userId } = getProfile();
    if (userId) {
      axiosInstant
        .get("/event/purchaseHistory")
        .then((res) => setPurchases(res.data))
        .catch((err) => console.log(err?.message));
    }
  }, []);

  if (!value) return <div />;
  return (
    <SettingsContainer sx={{ flexDirection: { xs: "column", sm: "row" } }}>
      <Tabs
        orientation={isXs ? "horizontal" : "vertical"}
        value={value}
        onChange={(_, e) => setValue(e)}
        aria-label="Settings tabs"
        {...SettingsTabStyle}
      >
        <Tab label="Account Settings" {...getTabProps(Settings.Account)} />
        {!!purchases?.length && (
          <Tab label="Purchase History" {...getTabProps(Settings.Purchase)} />
        )}
        <Tab label="Billing and Plans" {...getTabProps(Settings.Billing)} />
      </Tabs>
      <SettingsDetails>
        <GoBackBtn />
        <SettingTab value={value} index={Settings.Account}>
          <AccountSettings />
        </SettingTab>
        <SettingTab value={value} index={Settings.Purchase}>
          <PurchaseHistory purchases={purchases} />
        </SettingTab>
        <SettingTab value={value} index={Settings.Billing}>
          <BillingPlans />
        </SettingTab>
      </SettingsDetails>
    </SettingsContainer>
  );
};
