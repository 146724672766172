import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { Box } from "@mui/material";
import {
  EventCard,
  EventDetailBox,
  EventLocation,
  EventName,
  EventTime,
} from "./styles";

export const EventThumbnail = ({ event }) => {
  const textProps = { variant: "body1" };

  const location = useMemo(
    () =>
      [event?.city, event?.state, event?.country].filter((e) => !!e).join(", "),
    [event]
  );

  return (
    <Link
      to={`/event/${event.id}`}
      style={{ textDecoration: "none", color: "inherit" }}
    >
      <EventCard>
        <Box style={{ height: "50%" }}>
          <img
            src={event?.logo || "/placeholder-event.svg"}
            alt={event?.name || "placeholder"}
            style={{ width: "100%", maxHeight: "100%", objectFit: "content" }}
          />
        </Box>
        <EventDetailBox>
          <EventName variant="h6" component="h3">
            {event.name}
          </EventName>
          <EventLocation {...textProps}>{location}</EventLocation>
          <Box mt={1}>
            <EventTime {...textProps}>Start Date: {event.start_date}</EventTime>
            <EventTime {...textProps}>End Date: {event.end_date}</EventTime>
          </Box>
        </EventDetailBox>
      </EventCard>
    </Link>
  );
};
