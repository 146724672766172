import React, { useState } from "react";
import { Dialog } from "@mui/material";
import axios from "axios";
import { LoginForm } from "./LoginFormBody"; // Adjust the import path if necessary
import { SignUpForm } from "./SignUpFormBody";
import { BaseURL, saveAuthData } from "../common/constants";

export function LoginDialog({ onClose, onCreatePassword }) {
  const [formValues, setFormValues] = useState({
    email: "",
    password: "",
  });

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [isLogin, setIsLogin] = useState(true); // Start with login form

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const toggleForm = () => {
    setIsLogin((prev) => !prev); // Toggle between login and signup
  };

  const validate = () => {
    let tempErrors = {};
    if (!formValues.email) tempErrors.email = "Email is required";
    if (!formValues.password) tempErrors.password = "Password is required";
    setErrors(tempErrors);
    return Object.values(tempErrors).every((x) => x === "");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!loading && validate()) {
      try {
        setLoading(true);
        // Perform login or signup request
        const response = await axios.post(`${BaseURL}/auth/login`, {
          ...formValues,
        });
        if (response.status === 204) onCreatePassword?.(formValues.email);
        else {
          saveAuthData(response);
          onClose(); // Close popup if request is successful
          setTimeout(() => {
            window.location.reload();
          }, 200);
        }
        setTimeout(() => {
          setLoading(false);
          onClose(); // Close the dialog on success
        }, 1000);
      } catch (error) {
        setLoading(false);
        // Handle error (set specific error messages)
      }
    }
  };

  return (
    <Dialog open={true} onClose={onClose} aria-labelledby="form-dialog-title">
      {isLogin ? (
        <LoginForm
          formValues={formValues}
          handleInputChange={handleInputChange}
          handleSubmit={handleSubmit}
          loading={loading}
          errors={errors}
          onRegister={toggleForm} // Toggle to signup form
          onCreatePassword={onCreatePassword} // Pass this prop to LoginForm
        />
      ) : (
        <SignUpForm
          formValues={formValues}
          handleInputChange={handleInputChange}
          handleSubmit={handleSubmit}
          loading={loading}
          errors={errors}
          onLogin={toggleForm} // Switch back to login form
        />
      )}
    </Dialog>
  );
}
