import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { OutputFileView } from "./OutputFileView";
import { axiosInstant } from "../../common/api";

const ButnStyles = { sx: { height: 30, padding: "0px 10px", fontSize: 12 } };

export const HistoryTableActions = ({
  id,
  name,
  is_completed,
  output_file,
  status,
}) => {
  const [showPopup, setShowPopup] = useState(false);
  const [loading, setLoading] = useState(false);

  const downloadFile = () => window.open(output_file);
  const showFile = () => setShowPopup(true);
  const closePopup = () => setShowPopup(false);
  const resubmitRequest = async () => {
    setLoading(true);
    try {
      await axiosInstant.post("/enrich/retry", { id });
      window.location.reload();
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  if (is_completed) {
    return (
      <Box display="flex" gap={2}>
        <Button {...ButnStyles} variant="outlined" onClick={showFile}>
          View file
        </Button>
        <Button {...ButnStyles} variant="contained" onClick={downloadFile}>
          Download
        </Button>
        {showPopup && (
          <Dialog
            open
            onClose={closePopup}
            maxWidth="xl"
            fullWidth
            PaperProps={{ sx: { height: "80vh", borderRadius: 3 } }}
          >
            <DialogTitle
              sx={{
                textAlign: "center", // Centers the text
                fontSize: "24px", // Increases the font size
                width: "100%", // Ensures it takes up the full width of the Dialog
                fontWeight: "bold", // Optional: makes the text bold
                paddingY: 2.5,
              }}
            >
              {name}
              <IconButton
                onClick={closePopup}
                sx={{
                  position: "absolute",
                  right: 16,
                  top: 16,
                  color: "grey.500",
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent sx={{ paddingX: 3, paddingY: 2 }}>
              <OutputFileView url={output_file} />
            </DialogContent>
          </Dialog>
        )}
      </Box>
    );
  }

  if (status.toLowerCase() === "insufficient credits") {
    return (
      <Button
        {...ButnStyles}
        variant={loading ? "outlined" : "contained"}
        onClick={resubmitRequest}
        disabled={loading}
      >
        {loading ? "Retrying..." : `Retry`}
      </Button>
    );
  }
  return <></>;
};
