import React, { useMemo } from "react";
import {
  Box,
  MenuItem,
  Select,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { ArrowForward, DragHandle } from "@mui/icons-material";
import { GridCheckIcon, GridCloseIcon } from "@mui/x-data-grid";
import { isUrl } from "../../common/constants";

export const SelectField = ({
  label,
  data,
  values,
  onUpdate,
  name,
  options,
  validateUrl,
}) => {
  const value = useMemo(() => values?.[name], [values, name]);
  const exampleValue = useMemo(
    () => data?.find((val) => !!val?.[value])?.[value],
    [data, value]
  );

  const isAccurateValue = () =>
    validateUrl
      ? validateUrl(exampleValue)
      : exampleValue.length > 0 && !isUrl(exampleValue);

  return (
    <TableRow
      key={label}
      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
    >
      <TableCell width={150}>
        <Box display="flex" gap={2} alignItems="center">
          {isAccurateValue() ? (
            <GridCheckIcon sx={{ color: "#4ADE80" }} />
          ) : (
            <GridCloseIcon sx={{ color: "#f00" }} />
          )}
          <Typography variant="body1">{label}</Typography>
        </Box>
      </TableCell>
      <TableCell width={250}>
        <Box display="flex" gap={2} alignItems="center">
          <ArrowForward />
          <Select
            fullWidth
            value={value}
            onChange={(e) => onUpdate(name, e.target.value)}
            sx={{ borderRadius: 2, height: 40 }}
          >
            {options.map((option, index) => (
              <MenuItem value={option} key={`${name}-${option}-${index}`}>
                {option.replace(/"/g, "").replace(/'/g, "")}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </TableCell>
      <TableCell width={350}>
        <Box display="flex" gap={2} alignItems="center">
          <DragHandle />
          <Typography variant="body" color="4c4c4c">
            {exampleValue}
          </Typography>
        </Box>
      </TableCell>
    </TableRow>
  );
};
