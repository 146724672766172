import React from "react";
import { FieldText } from "./TextField";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  CircularProgress,
} from "@mui/material";

export function SignUpForm({ handleSubmit, loading, onLogin, ...commonProps }) {
  return (
    <>
      <DialogTitle id="form-dialog-title">Register</DialogTitle>
      <DialogContent sx={{ py: 0 }}>
        <DialogContentText>
          Let us know about you and the kind of people you want to connect with.
          This helps us serve you better.
        </DialogContentText>
        <FieldText {...commonProps} name="name" label="Name" />
        <FieldText {...commonProps} name="email" label="Email Address" />
        <FieldText {...commonProps} name="phone" label="Phone Number" />
        <FieldText {...commonProps} name="company" label="Company Name" />
        <FieldText
          {...commonProps}
          type="password"
          name="password"
          label="Password"
        />
        <FieldText
          {...commonProps}
          name="icp"
          label="Ideal Customer Profile"
          rows={4}
        />
      </DialogContent>
      <DialogActions>
        <Box
          display="flex"
          padding={2}
          justifyContent="center"
          width="100%"
          alignItems="center"
          flexDirection="column"
          gap={2}
        >
          <Button
            onClick={handleSubmit}
            color="primary"
            variant="contained"
            size="large"
            sx={{ px: 2, py: 1 }}
          >
            <Typography>Sign Up</Typography>
            {loading && (
              <CircularProgress
                color="inherit"
                size={16}
                sx={{ marginLeft: 1 }}
              />
            )}
          </Button>
          <Button
            onClick={onLogin}  // Toggle back to login form
            color="secondary"
            variant="text"
            size="large"
            sx={{ minWidth: "fit-content" }}
          >
            Sign In with existing account
          </Button>
        </Box>
      </DialogActions>
    </>
  );
}
