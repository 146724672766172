import React from "react";
import { Box } from "@mui/material";
import Divider from "@mui/material/Divider";
import { EventPageTitle } from "./styles";

export const PageHeading = () => {
  return (
    <Box>
      <EventPageTitle variant="h3">
        Get Advanced Insights for events around the world
      </EventPageTitle>
      <Divider />
    </Box>
  );
};
