import React from "react";
import {
  Typography,
  Grid,
  Card,
  Box,
  Divider,
  List,
  ListItem,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  Email as EmailIcon,
  LinkedIn as LinkedinIcon,
  Search as SearchIcon,
} from "@mui/icons-material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

// Styled components
const AIAgentsContainer = styled("div")(({ theme }) => ({
  width: "100%",
  maxWidth: "1200px",
  paddingLeft: "24px",
  paddingRight: "24px",
  paddingTop: "144px",
  paddingBottom: "96px",
  marginLeft: "auto",
  marginRight: "auto",
  // Add media queries using Material UI's theme breakpoints
  [theme.breakpoints.down("sm")]: {
    paddingTop: "80px",
    paddingBottom: "70px",
  },
}));

const StyledCard = styled(Card)(({ theme }) => ({
  minHeight: 400, // Increase the height of the cards
  borderRadius: "16px", // Rounder edges
  boxShadow: theme.shadows[2],
  transition: "transform 0.3s ease, box-shadow 0.3s ease",
  "&:hover": {
    transform: "translateY(-5px)",
    boxShadow: theme.shadows[4],
  },
  // Add media queries using Material UI's theme breakpoints
  [theme.breakpoints.down("sm")]: {
    borderRadius: "12px",
  },
}));

const CardContent = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  paddingTop: "32px",
  paddingBottom: "32px",
  paddingLeft: "28px",
  paddingRight: "28px",
  // Add media queries using Material UI's theme breakpoints
  [theme.breakpoints.down("sm")]: {
    paddingTop: "28px",
    paddingBottom: "24px",
    paddingLeft: "20px",
    paddingRight: "20px",
  },
}));

const IconTitleWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginBottom: theme.spacing(2),
}));

const IconWrapper = styled(Box)(({ theme }) => ({
  fontSize: 40,
  marginRight: theme.spacing(1),
  display: "flex",
}));

const Title = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  fontFamily: "Inter, Sans-serif",
  fontSize: "1.5rem", // Adjust the title size
}));

const Description = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontFamily: "Inter, Sans-serif",
}));

const DividerWrapper = styled(Box)(({ theme }) => ({
  margin: theme.spacing(2, 0),
}));

const ListWrapper = styled(Box)(({ theme }) => ({
  paddingLeft: theme.spacing(2),
}));

// Service data with bullet points
const services = [
  {
    title: "AI Emails Finder",
    description:
      "Our Emails AI Agent lets you find any business email instantly",
    icon: <EmailIcon sx={{ fontSize: "30px", color: "orange" }} />, // Increase the icon size
    points: [
      "Helps you find and verify emails",
      "You only pay for verified emails",
      "Enrich large CSV files with emails",
      "API to integrate with your workflows (coming soon)",
    ],
  },
  {
    title: "AI LinkedIn Enrichment",
    description:
      "Enrich data from any LinkedIn URL based on your preferred template",
    icon: <LinkedinIcon sx={{ fontSize: "30px", color: "blue" }} />, // Increase the icon size
    points: [
      "Enriches Data from given LinkedIn links",
      "Enrich Bulk Data in CSV",
      "Format data as you want",
      "API to integrate with your workflows (coming soon)",
    ],
  },
  {
    title: "AI Prospecting",
    description: "AI agent to help you find the right customer",
    icon: (
      <SearchIcon
        sx={{ fontSize: "30px", color: "purple", marginLeft: "5px" }}
      />
    ), // Increase the icon size
    points: [
      "Helps find customers based on your Ideal Customer Profile",
      "Connect data providers like Apollo (coming soon)",
      "API to integrate with your workflows (coming soon)",
    ],
  },
];

// Functional component
export function AIAgents() {
  return (
    <AIAgentsContainer
      id="agents"
      sx={{
        paddingTop: 18,
        paddingBottom: 12,
        paddingLeft: "24px",
        paddingRight: "24px",
      }}
    >
      <Typography
        component="h2"
        variant="h3"
        align="center"
        gutterBottom
        sx={{
          fontFamily: "Inter, Sans-serif",
          paddingBottom: "15px",
          fontWeight: "bold",
        }}
      >
        <span>
          Our AI Agents are Created to
          <br />
          <span style={{ color: "green" }}>Increase</span> Your{" "}
        </span>
        <span>
          <span style={{ color: "blue" }}>Growth</span> by{" "}
          <span style={{ color: "purple" }}>10x</span>
        </span>
      </Typography>
      <Typography
        variant="subtitle1"
        component="p"
        gutterBottom
        fontFamily="Inter, Sans-serif"
        sx={{
          fontSize: "18px",
          lineHeight: "1.5",
          color: "grey",
          textAlign: "center",
          paddingBottom: "50px",
          paddingTop: "0px",
          maxWidth: "580px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        Each Agent is created for a specific growth-related task but overall
        they are meant to skyrocket your Growth
      </Typography>
      <Grid
        container
        spacing={3}
        justifyContent="center"
        // sx={{
        //   maxWidth: "1060px",
        // }}
      >
        {services.map((service, index) => (
          <Grid item xs={12} sm={6} lg={4} key={index}>
            <StyledCard
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "100%",
                width: "100%",
                boxSizing: "border-box",
              }}
            >
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  height: "100%",
                }}
              >
                <div sx={{ flexGrow: 1 }}>
                  <IconTitleWrapper
                    sx={{
                      display: "flex",
                      alignItems: "flex-start",
                      marginBottom: 2,
                    }}
                  >
                    <IconWrapper>{service.icon}</IconWrapper>
                    <Title variant="h5" fontFamily="Inter, Sans-serif">
                      {service.title}
                    </Title>
                  </IconTitleWrapper>

                  <Description
                    variant="body1"
                    fontFamily="Inter, Sans-serif"
                    textAlign="left"
                    sx={{
                      marginBottom: 1,
                      overflow: "hidden",
                      minHeight: "50px",
                    }}
                  >
                    {service.description}
                  </Description>
                </div>
                <DividerWrapper sx={{ marginBottom: "20px" }}>
                  <Divider />
                </DividerWrapper>

                <ListWrapper sx={{ flexGrow: 1, paddingLeft: "0px" }}>
                  <List
                    sx={{
                      padding: 0,
                      fontFamily: "Inter, Sans-serif",
                      fontSize: "16px",
                      display: "flex",
                      flexDirection: "column",
                      gap: "4px",
                      flexGrow: 1,
                    }}
                  >
                    {service.points.map((point, idx) => (
                      <ListItem
                        key={idx}
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                          paddingLeft: "2px",
                          paddingTop: "6px",
                          paddingBottom: "6px",
                          margin: 0,
                        }}
                      >
                        <CheckCircleIcon
                          sx={{
                            color: "green",
                            marginRight: "8px",
                            marginTop: "1px",
                            fontSize: "1.4rem",
                          }}
                        />
                        {point}
                      </ListItem>
                    ))}
                  </List>
                </ListWrapper>
              </CardContent>
            </StyledCard>
          </Grid>
        ))}
      </Grid>
    </AIAgentsContainer>
  );
}

export default AIAgents;
