import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import axios from "axios";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import { useParams } from "react-router-dom";
import { EventBreadCrumbs } from "./BreadCrumbs";
import { BannerForFreeUser } from "./BannerForFreeUser";
import { EventTable } from "./EventTable";
import { EventLocation } from "./EventLocation";
import { EventDetail } from "./EventDetail";
import { BaseURL, getProfile } from "../common/constants";

export function ListSingleEvent() {
  const { email, token } = getProfile();
  const [tableRows, setTableRows] = useState([]);
  const [event, setEvent] = useState("");
  const { eventId } = useParams();

  useEffect(() => {
    const config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${BaseURL}/event?eventId=${eventId}`,
      headers: {
        "auth-email": email,
        Authorization: token,
      },
    };
  
    axios
      .request(config)
      .then((response) => {
        if (response.data?.participants) {
          setTableRows(response.data?.participants);
          setEvent(response.data?.event);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <Box sx={{ flexGrow: 1, marginTop: "20px" }}>
      <Grid container spacing={2}>
        <Grid item xs={1}></Grid>

        <Grid item xs={10}>
          <EventBreadCrumbs event={event} />
          <Card variant="outlined" sx={{ maxWidth: "100%" }}>
            <EventDetail event={event} />
            <Divider />
            <EventLocation event={event} tableRows={tableRows} />
          </Card>
          <BannerForFreeUser tableRowsCount={tableRows.length} event={event} />
          <br />
          <EventTable tableRows={tableRows} />
        </Grid>

        <Grid item xs={1}></Grid>
      </Grid>
    </Box>
  );
}

export default ListSingleEvent;
