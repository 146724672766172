import React, { useState } from "react";
import axios from "axios"; // Make sure to install axios if not already
import { Dialog } from "@mui/material";
import { SignUpForm } from "./SignUpFormBody";
import { BaseURL, saveAuthData } from "../common/constants";
import { LoginForm } from "./LoginFormBody"; // Import LoginForm

export function SignUpDialog({ onClose, ...rest }) {
  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    phone: "",
    password: "",
    company: "",
    icp: "",
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [isLoginForm, setIsLoginForm] = useState(false); // State to toggle between forms

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const validate = () => {
    let tempErrors = {};
    tempErrors.name = formValues.name ? "" : "This field is required.";
    tempErrors.email = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(formValues.email)
      ? ""
      : "Email is not valid.";
    tempErrors.phone = formValues.phone ? "" : "Phone number is not valid.";
    tempErrors.company = formValues.company ? "" : "This field is required.";
    tempErrors.icp = formValues.icp ? "" : "This field is required.";
    setErrors(tempErrors);

    return Object.values(tempErrors).every((x) => x === "");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!loading && validate()) {
      try {
        setLoading(true);
        const { phone, ...rest } = formValues;
        const response = await axios.post(`${BaseURL}/auth/register`, {
          ...rest,
          phone_number: formValues.phone,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          eventId: window.location.pathname.split("event/")?.[1],
        });
        saveAuthData(response);
        onClose(); // Close popup if request is successful
        setTimeout(() => {
          window.location.reload();
        }, 200);
      } catch (error) {
        setErrors((prev) => ({
          ...prev,
          email: error?.response?.data?.message,
        }));
        setLoading(false);
      }
    }
  };

  const handleToggle = () => {
    setIsLoginForm((prev) => !prev); // Toggle between login and signup forms
  };

  const loginProps = {
    handleInputChange,  // For the LoginForm
    formValues: { email: "", password: "" }, // Reset for login
    errors: { email: errors.email, password: "" }, // Only keep email error
    handleSubmit, // You can create a separate handleSubmit for Login if needed
    loading,
    onRegister: handleToggle, // Toggle to sign-up form
  };

  const signUpProps = {
    handleInputChange,
    formValues,
    errors,
    handleSubmit,
    loading,
    onLogin: handleToggle, // Pass toggle function to SignUpForm
  };

  return (
    <React.Fragment>
      <Dialog
        open
        onClose={onClose}
        aria-labelledby="form-dialog-title"
        disableEscapeKeyDown
        sx={{ backdropFilter: "blur(3px)" }} // Translucent backdrop
      >
        {isLoginForm ? (
          <LoginForm {...loginProps} />
        ) : (
          <SignUpForm {...signUpProps} {...rest} />
        )}
      </Dialog>
    </React.Fragment>
  );
}
