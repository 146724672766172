import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { MappedFieldsView } from "./MappedFieldsView";
import { BorderedText } from "./constants";

export const MappedFieldsInfo = ({ onSubmit, goBack, ...rest }) => {
  return (
    <Box display="flex" flexDirection="column" gap={4} width="100%">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        gap={2}
      >
        <Typography variant="h5">Match your columns</Typography>
        <BorderedText variant="body1">
          {rest.data.length} contacts found
        </BorderedText>
      </Box>
      <MappedFieldsView {...rest} />
      <Box display="flex" justifyContent="space-between">
        <Button variant="outlined" onClick={goBack}>
          Re-upload File
        </Button>
        <Button variant="contained" onClick={onSubmit}>
          Continue
        </Button>
      </Box>
    </Box>
  );
};
