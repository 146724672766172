import { CurrencyExchangeTwoTone } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import { getFormattedDate } from "../common/constants";
import React, { useState } from "react";
import { CancelSubscriptionPopup } from "./CancelSubscriptionPopup";

export function CurrentPlan({ data }) {
  const [showSubscriptionAlert, setShowSubscriptionAlert] = useState(false);

  const planName = data?.plan_name || "Free";
  return (
    <Box marginY={5} display="flex">
      <Box
        display="flex"
        flexDirection="column"
        border={1}
        borderColor="#e2e8ee"
        paddingY={4}
        paddingX={6}
        gap={1}
      >
        <Box display="flex" alignItems="center" gap={6}>
          <CurrencyExchangeTwoTone sx={{ fontSize: 44 }} />
          <Box display="flex" gap={2} flexDirection="column">
            <Typography variant="h6">
              You are currently on the <b>{planName}</b> plan
            </Typography>
            <Box>
              <Typography variant="body1">
                Your current remaining credits are{" "}
                {data?.credits?.toLocaleString() || 0} on the {planName} plan.
              </Typography>
              {!data?.expires_at && data?.credits_expire_at && (
                <Typography variant="body1">
                  Your credits will be expired on{" "}
                  {getFormattedDate(data?.credits_expire_at)}.
                </Typography>
              )}
              {data?.expires_at && (
                <Typography variant="body1">
                  Your subscription will be renewed on{" "}
                  {getFormattedDate(data?.expires_at)}.
                </Typography>
              )}
            </Box>
          </Box>
        </Box>
        {data?.plan_name && (
          <Box display="flex" width="100%" justifyContent="flex-end">
            <Button
              variant="text"
              onClick={() => setShowSubscriptionAlert(true)}
            >
              Cancel Subscription
            </Button>
          </Box>
        )}
      </Box>
      {showSubscriptionAlert && (
        <CancelSubscriptionPopup
          onClose={() => setShowSubscriptionAlert(false)}
        />
      )}
    </Box>
  );
}
