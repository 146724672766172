import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Typography,
} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import {
  StyledTableRow as TableRow,
  StyledTableCell as TableCell,
} from "./constants";
import { gray } from "../../theme/getLPtheme";

export function PurchaseList({ purchases }) {
  if (!purchases.length) return <Typography>No Purchase Found</Typography>;

  return (
    <TableContainer
      component={Paper}
      sx={{
        maxHeight: 500,
        "& .MuiTableCell-body": {
          color: gray[800],
        },
      }}
    >
      <Table sx={{ border: 0.5 }} stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell
              sx={{ width: { xs: "unset", sm: "calc(100vw - 400px)" } }}
            >
              Event Name
            </TableCell>
            <TableCell
              align="center"
              sx={{ width: { xs: "unset", sm: "200px" } }}
            >
              Price Paid (usd)
            </TableCell>
            <TableCell
              align="center"
              sx={{ width: { xs: "unset", sm: "200px" } }}
            >
              Action
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {purchases.map((row) => (
            <TableRow
              key={row.name}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="center">{row.price_paid}</TableCell>
              <TableCell align="center">
                <Link to={`/event/${row.id}`} target="_blank">
                  See event
                </Link>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
