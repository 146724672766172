import React from "react";
import { isEmpty, transform } from "lodash";
import { Button, Typography } from "@mui/material";
import * as xlsx from "xlsx";
import { isSpreadsheet, UploadFilePanel } from "./constants";
import { UploadFileView } from "./UploadFileView";
import { useCSVReader } from "react-papaparse";

export const UploadDataFile = ({ onUploadFile, ...rest }) => {
  const { CSVReader } = useCSVReader();

  const uploadFile = (results, file) => {
    if (file) {
      const reader = new FileReader();

      if (isSpreadsheet(file.type)) {
        reader.onload = (event) => {
          const data = event.target?.result;
          const workbook = xlsx.read(data, { type: "array" });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const json = xlsx.utils.sheet_to_json(worksheet);
          const output = json.map((e) =>
            transform(e, (result, val, key) => {
              result[key] = val;
            })
          );
          onUploadFile(file, output);
        };
        reader.readAsArrayBuffer(file);
      } else {
        const dataArray = results?.data || [];
        if (dataArray.length > 1) {
          const header = dataArray[0];
          const output = [];
          for (let row = 1; row < dataArray.length; row += 1) {
            const obj = {};
            for (const index in header) {
              const val = dataArray[row][index];
              obj[header[index]] = val || '';
            }
            if (!isEmpty(obj)) output.push(obj);
          }
          onUploadFile(file, output);
        }
      }
    }
  };

  return (
    <UploadFilePanel
      display="flex"
      flexDirection="column"
      gap={2}
      borderRadius={5}
    >
      <Typography variant="h5">Click to upload CSV/XLS file</Typography>
      <UploadFilePanel sx={{ border: "none" }}>
        <Typography variant="body">The Agent will take your CSV/XLSX file to start acting on it. It will require the file to contain the following to act. Make sure you map accordingly.</Typography>
        <UploadFileView {...rest} />
      </UploadFilePanel>
      <CSVReader accept=".csv, .xlsx" onUploadAccepted={uploadFile}>
        {({ getRootProps }) => (
          <Button variant="contained" {...getRootProps()}>
            Upload CSV/Excel
          </Button>
        )}
      </CSVReader>
    </UploadFilePanel>
  );
};
