import React from "react";
import { Box, Typography } from "@mui/material";
import { BorderedText, EnrichTypes } from "./constants";

export const UploadFileView = ({ type }) => {
  if (type === EnrichTypes.Email)
    return (
      <Box display="flex" gap={2} alignItems="center">
        <BorderedText variant="body1">First Name</BorderedText>
        <Typography variant="h6">+</Typography>
        <BorderedText variant="body1">Last Name</BorderedText>
        <Typography variant="h6">+</Typography>
        <BorderedText variant="body1">Company Website</BorderedText>
      </Box>
    );
  if (type === EnrichTypes.Linkedin)
    return (
      <Box display="flex" gap={2} alignItems="center">
        <BorderedText variant="body1">LinkedIn URL</BorderedText>
      </Box>
    );
  return <></>;
};
