import React from "react";
import MenuItem from "@mui/material/MenuItem";
import { Link } from "react-router-dom";
import { HeaderLinksStyle } from "./styles";

export function SmMenuItem({ label, href, onClick }) {
  const menuItem = (
    <MenuItem onClick={() => onClick?.(label.toLowerCase())}>{label}</MenuItem>
  );

  if (!href) return menuItem;
  return (
    <Link to={href} style={HeaderLinksStyle}>
      {menuItem}
    </Link>
  );
}
