import { Box, Button, Card, Chip, Container, styled } from "@mui/material";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";

export const HeroContainer = styled(Container)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  minHeight: "65vh",
  paddingTop: "50px",
  paddingBottom: "50px",
  paddingLeft: "24px",
  paddingRight: "24px",
  textAlign: "center",
}));

export const HeroButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(10),
  padding: theme.spacing(1.5, 4),
  fontSize: "1.25rem",
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.palette.secondary.main,
  "&:hover": {
    backgroundColor: theme.palette.secondary.dark,
  },
  [theme.breakpoints.down("sm")]: {
    marginTop: theme.spacing(3),
  },
}));

export const CheckBlock = styled(Box)(({ theme }) => ({
  display: "flex",
}));

export const VideoContainer = styled(Box)(({ theme }) => ({
  paddingLeft: "24px",
  paddingRight: "24px",
}));

export const VideoBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  position: "relative",
  // Add media queries using Material UI's theme breakpoints
  [theme.breakpoints.down("md")]: {
    paddingTop: "56.25%",
  },
}));

export const LogoCollectionStyle = {
  width: "100px",
  height: "90px",
  margin: "0 32px",
  opacity: 0.9,
};

export const TestimonialLogoStyle = {
  width: "64px",
  opacity: 0.7,
};

export const TestimonialContainer = styled(Container)(() => ({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));

export const TestimonialContainerStyle = {
  paddingTop: { xs: 4, sm: 12 },
  paddingBottom: { xs: 8, sm: 16 },
  paddingLeft: { xs: 3, sm: 3 },
  paddingRight: { xs: 3, sm: 3 },
};

export const TestimonialCard = styled(Card)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  flexGrow: theme.spacing(1),
  paddingTop: "32px",
  paddingBottom: "28px",
  paddingLeft: "28px",
  paddingRight: "28px",
  borderRadius: "16px",
  // Add media queries using Material UI's theme breakpoints
  [theme.breakpoints.down("sm")]: {
    paddingTop: "28px",
    paddingBottom: "24px",
    paddingLeft: "20px",
    paddingRight: "20px",
    textAlign: "center",
    borderRadius: "12px",
  },
}));

export const TestimonialBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  justifyContent: "space-between",
  gridGap: "10px",
  paddingRight: theme.spacing,
  // Add media queries using Material UI's theme breakpoints
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    alignItems: "center",
  },
}));

export const TestimonialBoxStyle = {
  width: { xs: "100%", md: "60%" },
  textAlign: "center",
};

export const CardContent = styled(Box)(({ theme }) => ({
  width: "100%",
  marginBottom: "18px",
  // Add media queries using Material UI's theme breakpoints
  [theme.breakpoints.down("sm")]: {
    marginBottom: "28px",
  },
}));

export const PricingContainerStyle = {
  pt: { xs: 4, sm: 12 },
  pb: { xs: 8, sm: 16 },
  position: "relative",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: { xs: 3 },
};

export const PricingWrapperStyle = {
  width: { sm: "100%", md: "60%" },
  textAlign: "center",
};

export const StyledPricingCard = styled(Card)(({ $tier, theme }) => ({
  padding: theme.spacing(2),
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(4),
  borderRadius: "16px",
  border: $tier.title === "Professional" ? "1px solid" : undefined,
  borderColor:
    $tier.title === "Professional" ? theme.palette.primary.main : undefined,
  background:
    $tier.title === "Professional"
      ? "linear-gradient(#033363, #021F3B)"
      : undefined,
  // Add media queries using Material UI's theme breakpoints
  [theme.breakpoints.down("sm")]: {
    padding: "4px",
  },
}));

export const PricingCardTitle = styled(Box)(({ $tier, theme }) => ({
  marginBottom: theme.spacing(1),
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  color: $tier.title === "Professional" ? theme.palette.grey[100] : "",
}));

export const StyledChip = styled(Chip)(({ theme }) => ({
  background: (theme) => (theme.palette.mode === "light" ? "" : "none"),
  backgroundColor: "primary.contrastText",
  "& .MuiChip-label, & .MuiChip-icon": {
    color: theme.palette.primary.dark,
  },
}));

export const PricingSubTitle = styled(Box)(({ $tier, theme }) => ({
  display: "flex",
  alignItems: "baseline",
  color: $tier.title === "Professional" ? theme.palette.grey[50] : undefined,
}));

export const PricingCheckbox = styled(CheckCircleRoundedIcon)(
  ({ theme, $tier }) => ({
    width: 20,
    color:
      $tier.title === "Professional"
        ? theme.palette?.primary?.light
        : theme.palette?.primary?.main,
  })
);

export const FaqsWrapper = {
  pt: { xs: 4, sm: 12 },
  pb: { xs: 8, sm: 16 },
  position: "relative",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: { xs: 3, sm: 6 },
};
