import axios from "axios";
import { BaseURL, TOKEN_KEY } from "./constants";
import RequestHandler from "axios-request-handler";

export const axiosInstant = axios.create({
  baseURL: BaseURL,
});

export const axiosHandler = (url) =>
  new RequestHandler(url, {
    baseURL: BaseURL,
    headers: { authorization: localStorage.getItem(TOKEN_KEY) },
  });

axiosInstant.interceptors.request.use((axiosConfig) => {
  const token = localStorage.getItem(TOKEN_KEY);
  if (!token) return { ...axiosConfig };
  return { ...axiosConfig, headers: { authorization: token } };
});

export const logoutUser = () => {
  localStorage.clear();
  window.location.pathname = "/";
};

axiosInstant.interceptors.response.use(
  (response) => {
    if (response.status === 401) logoutUser();
    return response;
  },
  (error) => {
    if (error?.response?.status === 401) logoutUser();
    return Promise.reject(error);
  }
);
