import { formatDate } from "date-fns";

// export const BaseURL =
//   process.env.NODE_ENV === "development"
//     ? "http://localhost:4000"
//     : "https://talk.allurt.co";

export const BaseURL = "https://talk.allurt.co";

export const TOKEN_KEY = "token";

const StripeUrl = "https://buy.stripe.com/bIY7uE6vQ0VG33adRm";

export const openStripeUrlPage = (event) => {
  const { payment_link, id: eventId } = event || {};

  const { userId, email } = getProfile();
  if (!userId || userId === "undefined") return;

  const url = `${
    payment_link || StripeUrl
  }?prefilled_email=${email}&client_reference_id=${eventId}-${userId}`;

  window.open(url, "_blank").focus();
};

export const getProfile = () => {
  const email = localStorage.getItem("authEmail");
  const name = localStorage.getItem("name");
  const company = localStorage.getItem("company");
  const userId = localStorage.getItem("userId");
  const token = localStorage.getItem(TOKEN_KEY);
  return { name, email, company, userId, token };
};

export const saveAuthData = (response) => {
  const { token, authEmail, name, company, id } = response.data;

  if (token) localStorage.setItem("token", token);
  if (authEmail) localStorage.setItem("authEmail", authEmail);
  if (id) localStorage.setItem("userId", id);
  if (name) localStorage.setItem("name", name);
  if (company) localStorage.setItem("company", company);
};

export const urlRegex = new RegExp(
  "^(https?:\\/\\/)?((([-a-z0-9]{1,63}\\.)*?[a-z0-9]([-a-z0-9]{0,253}[a-z0-9])?\\.[a-z]{2,63})|((\\d{1,3}\\.){3}\\d{1,3}))(:\\d{1,5})?((\\/|\\?)((%[0-9a-f]{2})|[-\\w\\+\\.\\?\\/@~#&=])*)?$",
  "i"
);

export const isUrl = (url) => {
  if (!url || typeof url !== "string") return false;
  try {
    new URL(url);
    return urlRegex.test(url);
  } catch (e) {
    return false;
  }
};

export const Settings = {
  Account: "account",
  Purchase: "purchase",
  Billing: "billing",
};

export const goToSettingsTab = (tab) => `/settings?tab=${tab}`;

export const getFormattedDate = (date) =>
  formatDate(new Date(date), "EEE, MMMM dd, yyyy");
