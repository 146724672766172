import * as React from "react";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { UseAuth } from "../profile/AuthContext";
import { GoBackBtn } from "../common/GoBackBtn";
import {
  Container,
  SingleEnrichmentOption,
  HistoryTable,
  HistoryTableStyle,
} from "./components";

export const EnrichmentOptions = () => {
  const navigate = useNavigate();
  const { showLoginPopup, checkIsLoggedIn } = UseAuth();

  const goToCsvUpload = () => navigate("/enrichment/email/csv-upload");

  React.useEffect(() => {
    if (!checkIsLoggedIn()) showLoginPopup(true);
  }, []);

  return (
    <Container>
      <HistoryTable {...HistoryTableStyle}>
        <Box marginTop={5}>
          <GoBackBtn />
          <Typography variant="h5">AI Email Enrichment</Typography>
        </Box>
        <Box marginTop={2} display="flex" flexDirection="column" gap={4}>
          <Typography variant="h6">Find Email of contacts</Typography>
          <SingleEnrichmentOption
            head="From a CSV / Excel"
            body="Enrich contains in bulk using spreadsheet"
            onClick={goToCsvUpload}
          />
         
        </Box>
      </HistoryTable>
    </Container>
  );
};
